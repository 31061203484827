<div class="nav-section">
	<div class="nav-main">
		<div class="default-nav">
            <app-desktop-navigation-item
                *ngIf="sportsDropdownData"
                [navItem]="sportsDropdownData"
                [firstItem]="true"
            ></app-desktop-navigation-item>
            
            <app-desktop-navigation-item
                [navItem]="{
                    type: 'dropdown',
                    title: 'Best Bets',
                    href: '/best-bets',
                    subLinks: picksPageOptions
                }"
            ></app-desktop-navigation-item>
            
            <app-desktop-navigation-item
                [navItem]="{
                    type: 'dropdown',
                    title: 'Best Props',
                    href: '/prop-bets',
                    subLinks: propsPageOptions
                }"
            ></app-desktop-navigation-item>
            
            <app-desktop-navigation-item
                [navItem]="{
                    type: 'dropdown',
                    title: 'Parlay Picker',
                    href: '/parlay-picker',
                    subLinks: parlayPickerPageOptions
                }"
            ></app-desktop-navigation-item>
            
            <app-desktop-navigation-item
                *ngIf="bettingDropdownData"
                [condensedSecondLevel]="true"
                [navItem]="bettingDropdownData"
            ></app-desktop-navigation-item>

            <app-desktop-navigation-item
                [navItem]="{
                    type: 'dropdown',
                    title: 'Dimers Pro',
                    href: '/subscription',
                    subLinks: [
                        {
                            type: 'link',
                            title: 'Pricing',
                            href: '/subscription'
                        },
                        {
                            type: 'link',
                            title: 'Get 3 Months Free',
                            href: '/subscription/promotion/betmgm'
                        },
                        {
                            type: 'link',
                            title: 'How To Use',
                            href: '/subscription/how-to-use-dimers-pro'
                        },
                        {
                            type: 'link',
                            title: 'FAQ',
                            href: '/subscription/faq'
                        },
                        {
                            type: 'link',
                            title: 'Become an Affiliate',
                            href: '/subscription/affiliate'
                        },
                        {
                            type: 'link',
                            title: 'Suggest a Feature',
                            href: 'https://dimers.supahub.com/',
                            strictHref: true,
                            newTab: true,
                        },
                    ]
                }"
            ></app-desktop-navigation-item>

            <app-desktop-navigation-item
                [navItem]="{
                    type: 'more-dropdown',
                    subLinks: [
                        {
                            type: 'link',
                            title: 'Live Scores',
                            iconPath: 'https://imgix.cosmicjs.com/544b7150-58fb-11ee-94c0-5f69598aa628-LiveNow-OnDark.svg?auto=format,compress&w=25',
                            href: '/live-now'
                        },
                        {
                            type: 'link',
                            title: 'Betting Explained',
                            iconPath: 'https://imgix.cosmicjs.com/432c2730-58eb-11ee-94c0-5f69598aa628-BettingExplained-OnDark.svg?auto=format,compress&w=25',
                            href: '/sports-betting-101'
                        },
                        {
                            type: 'link',
                            title: 'Dimers For Business',
                            iconPath: 'https://imgix.cosmicjs.com/839aa250-9320-11ee-8a8d-abf95e574482-business-icon.svg?auto=format,compress&w=25',
                            href: '/business'
                        },
                        {
                            type: 'link',
                            title: 'Gamble Responsibly',
                            iconPath: 'https://imgix.cosmicjs.com/4325e5a0-58eb-11ee-94c0-5f69598aa628-GambleResponsibly-OnDark.svg?auto=format,compress&w=25',
                            href: '/responsible-gambling'
                        },
                        {
                            type: 'link',
                            title: 'Pricing',
                            iconPath: 'https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg?auto=format,compress&w=25',
                            href: '/subscription'
                        },
                        {
                            type: 'link',
                            title: 'News',
                            iconPath: 'https://imgix.cosmicjs.com/f8f63920-220d-11ef-9fc0-dbae8bde9933-News-OnDark.svg?auto=format,compress&w=25',
                            href: '/news'
                        },
                        {
                            type: 'link',
                            title: 'Join newsletter',
                            iconPath: 'https://imgix.cosmicjs.com/432b15c0-58eb-11ee-94c0-5f69598aa628-Newsletter-OnDark.svg?auto=format,compress&w=25',
                            onClick: fireNewsletterPopUp
                        },
                    ]
                }"
                [dropdownCentred]="true"
            ></app-desktop-navigation-item>

		</div>
	</div>

	<div class="nav-extras">
		<ng-container *ngIf="authHelper.userRetrievalDone && authHelper.user">
            <button *ngIf="(proUser$ | async) === false"  class="pro-button w-full" (click)="openProModal()">
                <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6 mr-2"> 
                Try Dimers Pro
            </button>
            <app-desktop-navigation-item *ngIf="(affiliate$ | async) === true"
                type="user-dropdown"
                [navItem]="affiliateAccountDropdownData"
            ></app-desktop-navigation-item>
            <app-desktop-navigation-item *ngIf="(affiliate$ | async) === false"
                type="user-dropdown"
                [navItem]="accountDropdownData"
            ></app-desktop-navigation-item>
		</ng-container>

		<ng-container *ngIf="authHelper.userRetrievalDone && !authHelper.user">
			<div class="login-button" (click)="authHelper.goToLoginWithState()">Log in</div>
			<div class="signup-button" (click)="authHelper.goToLoginWithState(true, '/my-account')">Join for free</div>
		</ng-container>

	</div>
</div>
