import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { BlogService } from 'src/app/blog.service';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-subscribe-click-prompt',
    templateUrl: './subscribe-click-prompt.component.html',
    styleUrls: ['./subscribe-click-prompt.component.scss'],
    standalone: true,
    imports: [FontAwesomeModule, NgIf, FormsModule]
})
export class SubscribeClickPromptComponent implements AfterViewInit {

  @Input() hideCallback: () => void;
  @Input() visible: boolean;

  faTimes = faTimes;

  @ViewChild("formContainer") containerElement: ElementRef;

  formSending = false;
  formSent = false;
  formError = false;
  formValid = false;
  email = "";

  constructor(
    private blogService: BlogService,
  ) { }

  ngAfterViewInit(): void {
    if (typeof document !== "undefined") {
      // if (Array.from(this.containerElement.nativeElement.children).some(s => s instanceof HTMLScriptElement && s.src === `https://ams.ciphersports.io/form/generate.js?id=18`)) {
      //   const oldScript: HTMLScriptElement = Array.from(this.containerElement.nativeElement.children).find(s => s instanceof HTMLScriptElement && s.src === `https://ams.ciphersports.io/form/generate.js?id=18`) as HTMLScriptElement;
      //   oldScript.remove();
      // }
        
      // let js;
      // js = document.createElement("script");
      // js.defer = "true";
      // js.src = `https://ams.ciphersports.io/form/generate.js?id=18`;
      // (this.containerElement.nativeElement as HTMLElement).appendChild(js);
    }
	}

  closeOverlay() {
    this.hideCallback();
  }

  sendEmail(): void {

    if (this.formValid) {
      this.formSending = true;
      this.formSent = false;
      this.formError = false;

      this.blogService.addNewsletterEmail(this.email)
        .subscribe(_ => {
          this.formSent = true;
          this.formSending = false;
        }, () => {
          this.formError = true;
          this.formSending = false;
        })
    }
  }

  verifyForm(): void {
    if (!this.email) {
      this.formValid = false;
    } else if (!/.+@.+\..+/.test(this.email)) {
      this.formValid = false;
    } else {
      this.formValid = true;
    }
  }
  
}
