<header [class.hidden-scrore]="!this.showScore"  [class.hidden-mobile-offers]="!this.showMobileOffers">
	<!-- <div *ngIf="bannerVisible" (click)="closeBanner()" class="h-9 dimers-business-banner flex items-center justify-center bg-highlight-100 text-white text-sm font-semibold underline cursor-pointer relative" routerLink="/subscription" >
		<span>Dimers Pro launches 3/11/2024. </span>  
        <span class="hidden sm:inline-block">&nbsp;Join the waitlist for 25% off</span>   
		<fa-icon [icon]="faAngleRight" aria-hidden="true" class="ml-2"></fa-icon>
		<fa-icon class="absolute right-2" [icon]="faXmark" aria-hidden="true" (click)="closeBanner()"></fa-icon>
	</div> -->
	<div class="carousel-shell">
		<div class="logo-carousel-ctr">
			<a routerLink="/" class="si-logo">
				<img alt="Dimers" loading="lazy" src="https://cdn.ciphersports.io/images/dimers-site/dimers-yellow.svg"
					height="68" width="300">
			</a>
            <div class="match-carousel">
				<app-game-bar-block *ngIf="gameBarSports"
				[sports]="gameBarSports"
				></app-game-bar-block>
			</div>
		</div>
	</div>
	<app-desktop-navigation
		[dropdownSports]="dropdownSports"
		[otherSports]="otherSports"
		[liveLightVisible]="liveLightVisible"
		[fireNewsletterPopUp]="fireNewsletterPopUp"
	></app-desktop-navigation>
	<app-mobile-navigation
		[dropdownSports]="dropdownSports"
		[otherSports]="otherSports"
		[liveLightVisible]="liveLightVisible"
		[fireNewsletterPopUp]="fireNewsletterPopUp"
	></app-mobile-navigation>
    <app-mobile-sticky-banner class="sm:hidden"></app-mobile-sticky-banner>
</header>
