import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-flag-icon',
    templateUrl: './flag-icon.component.html',
    styleUrls: ['./flag-icon.component.scss'],
    standalone: true
})
export class FlagIconComponent {
  @Input() countryCode: string;
  constructor() { }

}
