import { NgModule } from '@angular/core';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { AuthHttpInterceptor, AuthModule, HttpMethod } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    AppModule,
		AuthModule.forRoot({
			domain: 'auth.dimers.com',
			clientId: 'qVJ8L2Zf855PoDh0j6sbC4laC18wz4Fn',
			authorizationParams: {
				audience: "https://dimers.us.auth0.com/api/v2/",

				scope: "read:current_user update:current_user_metadata",
				redirect_uri: window.location.origin + "/callback",
				// cacheLocation: "localstorage",
				// useRefreshTokens: true,
			},
			httpInterceptor: {
				allowedList: [
					{
						uri:"https://auth.dimers.com/api/v2/*",
						httpMethod: HttpMethod.Get,
						tokenOptions: {
							authorizationParams: {
								audience: "https://dimers.us.auth0.com/api/v2/",
								scope: "read:current_user"
							}
						}
					},
					{
						uri: "https://auth.dimers.com/api/v2/users/*",
						httpMethod: HttpMethod.Patch,
						tokenOptions: {
							authorizationParams: {
								audience: "https://dimers.us.auth0.com/api/v2/",
								scope: "update:current_user_metadata"
							}
						}
					},
				]
			}
			

			
		}),
		NoopAnimationsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthHttpInterceptor,
    multi: true
  }]
})
export class AppBrowserModule {}
