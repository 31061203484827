import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { NEVER, Observable, of } from 'rxjs';
import { concatMap, map, shareReplay, take } from 'rxjs/operators';

class DetailedUser extends User {
  app_metadata?: Record<string, any>;
  user_metadata?: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthHelperService {

  user: any = null;
  userShortlist = [];
  userRetrieved = false;
  userRetrievalDone = false;

  detailedUser$: Observable<DetailedUser> = typeof window !== "undefined" ? this.auth.user$
  .pipe(
    take(1),
    concatMap((user) => {
      if (typeof window === "undefined") {
        return of(null);
      }
      this.userRetrievalDone = false;
      if (user && user.sub && !this.userRetrieved) {
        this.user = user;
        return this.http.get(encodeURI(`https://auth.dimers.com/api/v2/users/${user.sub}`))
      } else {
        return of(null);
      }
    }),
    map((meta: any) => {
      if (meta) {
        this.user.email = meta.email;
        this.user.email_verified = meta.email_verified;
        this.user.user_metadata = meta.user_metadata;
        this.user.app_metadata = meta.app_metadata;
        this.user.logins_count = meta.logins_count;
        if (!meta.user_metadata?.match_shortlist) {
          this.userShortlist = [];
        } else {
          this.userShortlist = meta.user_metadata.match_shortlist;
        }
      }

      this.userRetrieved = true;
      this.userRetrievalDone = true;
      return this.user;
    }),
    shareReplay(1)
  ) : NEVER;

  proUser$: Observable<boolean> = this.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_pro
		}),
		shareReplay(1)
	);

  constructor(private auth: AuthService, private http: HttpClient) {
    if (typeof window !== "undefined") {
    this.detailedUser$.subscribe();
    }
    
  }

  goToLoginWithState(signUpPanel?: boolean, overrideTarget?: string): void {
    // TODO set local storage
    this.auth.loginWithRedirect({
      appState: {
        target: overrideTarget || (window.location.pathname + window.location.search)
      },
      authorizationParams: {
        redirect_uri: window.location.origin + "/callback",
        screen_hint: signUpPanel ? "signup" : undefined,
        logged_in_at_path: window.location.pathname + window.location.search,
      }
    });
  }

  logout(options?: Record<string, any>) {
    this.auth.logout({logoutParams: options});
  }


}
