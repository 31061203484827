import { Component, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-about-dimers',
    templateUrl: './about-dimers.component.html',
    styleUrls: ['./about-dimers.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true
})
export class AboutDimersComponent {}
