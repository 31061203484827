<div #blockBody class="external-game-bar">
	<div class="game-bar-loading" *ngIf="gamesLoading && !gamesError">
		<span>
			Loading upcoming games<span class="animated-ellipsis"><span class="dot dot1">.</span><span class="dot dot2">.</span><span class="dot dot3">.</span></span>
		</span>
	</div>
	<div class="game-bar-loading" *ngIf="gamesError">
		<span class="error-main">
			<span>
				We are having trouble loading upcoming games data
			</span>
		</span>

		<span>
			Please
			<span class="retry-link"
				(click)="reloadGames()">
				try again
			</span>
		</span>
	</div>
	<div class="shift-button left active" *ngIf="!gamesLoading && !gamesError" (click)="shiftLeft()">
		<fa-icon [icon]="faAngleLeft"></fa-icon>
	</div>
	<div class="game-bar-games" *ngIf="!gamesLoading && !gamesError">

		<div class="game-track" id="si-game-track" (click)="tileClick($event)" (pointerdown)="startGrab($event)">
			<ng-container *ngFor="let sport of gameBarSportOptions; index as groupIndex">
				<div class="game-sport-group"
					*ngIf="stripInteractedWith || !defaultVisibleSportCount || defaultVisibleSportCount > groupIndex"
				>
					<div class="sport-group-header"
						[class.origin]="(sport | uppercase) === 'SOO'"
						[class.championship]="championshipModeLeagues.includes(sport | uppercase)">
						<app-sport-icon alt="" *ngIf="sport" class="icon"
							draggable="false"
							loading="lazy"
							[code]="sport"
							[colour]="championshipModeLeagues.includes(sport | uppercase) ? 'black' : 'white'"></app-sport-icon>
						{{ generalService.getShortName(sport) }}
					</div>
                    <ng-container *ngFor="let match of displayedMatchesDict[sport]; index as index">
                        <a override-callback draggable="false"
						[href]="midGrab ? '' : generalService.getMatchLink(match.MatchData.SIMatchID)"
                        [attr.data-match-status]="match?.LiveData?.status || 'upcoming'"
                        [attr.data-sport]="match.MatchData.Sport"
                        [attr.data-match-id]="match.MatchData.SIMatchID"
						class="game-link"
                        attr.data-recording-click-props='{
                            "match-sport": "{{ sport }}",
                            "match-group-index": "{{ groupIndex + 1 }}",
                            "match-index": "{{ index + 1}}",
                            }'
                        >
                            <app-mini-match-tile [match]="match"></app-mini-match-tile>
                        </a>
                    </ng-container>

				</div>
			</ng-container>
		</div>
	</div>
	
	<div class="shift-button right active" *ngIf="!gamesLoading && !gamesError" (click)="shiftRight()">
		<fa-icon [icon]="faAngleRight"></fa-icon>
	</div>
</div>