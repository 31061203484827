import { Injectable } from '@angular/core';
import { compareAsc, differenceInHours, differenceInMilliseconds, differenceInMinutes, format, getMinutes, isBefore } from 'date-fns';
import { Match } from './match.model';
import { SPORTS } from './sports';
import { GeneralService } from "src/app/general.service";
import { MatchLiveData } from './match-live-data.model';
import { NthPipe } from 'src/app/nth/nth.pipe';
import { LocalisationService } from './localisation.service';
import { IsPreMatchOnlyPipe } from 'src/app/match-pipes/is-pre-match-only.pipe';
import { IsLivePipe } from 'src/app/match-pipes/is-live.pipe';
import { IsOverPipe } from './match-pipes/is-over.pipe';
import { IsPausedPipe } from './match-pipes/is-paused.pipe';
import { ScoreStripEntry } from './score-strip-entry.model';

@Injectable({
  providedIn: 'root'
})
export class MatchService {



	userRetrievedLat: number | undefined;
	userRetrievedLon: number | undefined;

	teamProximityThreshold: number;

	geoCountry: string;
	geoRegion: string;

	constructor(
		private nthFilter: NthPipe,
		private generalService: GeneralService,
		private localisationService: LocalisationService,
		private isPreMatchOnlyPipe: IsPreMatchOnlyPipe,
		private isLivePipe: IsLivePipe,
		private isOverPipe: IsOverPipe,
		private isPausedPipe: IsPausedPipe,
	) {

		this.userRetrievedLat = undefined;
		this.userRetrievedLon = undefined;
		this.teamProximityThreshold = 100e3; // A team must be based within 100km of the user to qualify as "nearby"

		this.geoCountry = "";
		this.geoRegion = "";
	}

	// isLive(match: Match): boolean {
	// 	return match && match.LiveData
	// 		&& (match.LiveData.status === "live" || match.LiveData.status === "inprogress" || match.LiveData.status === "halftime")
	// 		&& !this.isPreMatchOnlyPipe.transform(match);
	// }

	// isPaused(match: Match): boolean {
	// 	return this && match.LiveData
	// 		&& (false) // TODO add types of delay, including weather etc
	// 		&& !this.isPreMatchOnlyPipe.transform(match);
	// }

	// isOver(match: Match): boolean {
	// 	return this && match.LiveData 
	// 		&& (match.LiveData.status === "completed" || match.LiveData.status === "ended" || match.LiveData.status === "retirement")
	// 		&& !this.isPreMatchOnlyPipe.transform(match);
	// }

	isUpcoming(match: Match | ScoreStripEntry): boolean {
		if (!this) {
			return false;
		}

		if (this.isPreMatchOnlyPipe.transform(match)) {
			return isBefore(new Date(), match.MatchData.UserDate);
		}

		return !match.LiveData || !match.LiveData.status;
	}

	// isPreMatchOnly(match: Match): boolean {
	// 	if (!match) {
	// 		return true;
	// 	}
	// 	if (match.LiveData && match.LiveData.error) {
	// 		return true;
	// 	}
	// 	const sportCode = match.MatchData.Sport.toUpperCase();
	// 	return !SPORTS.some(s => s.code === sportCode && s.hasLiveMatchPredictions);
	// }

	startsWithinHour(match: Match | ScoreStripEntry): boolean {
		if (!match) {
			return false;
		}
		if (this.isPreMatchOnlyPipe.transform(match)) {
			return differenceInMinutes(new Date(), match.MatchData.UserDate) > -60
				&& isBefore(new Date(), match.MatchData.UserDate);
		}

		return match && (!match.LiveData || !match.LiveData.status) &&
			differenceInMinutes(new Date(), match.MatchData.UserDate) > -60;
	}

	matchStartCountdown(match: Match | ScoreStripEntry, verbose: boolean): string {
		if (match?.MatchData?.UserDate) {
			if (verbose) {
				const hoursleft = differenceInMilliseconds(match.MatchData.UserDate, new Date()) / (1000 * 3600);
				if (hoursleft < 0) {
					return "Soon";
				} else if (hoursleft < 1) {
					// minutes remaining
					const minutesleft = hoursleft * 60;
					return "In " + minutesleft.toFixed(0) + (minutesleft.toFixed(0) == "1" ? " minute" : " minutes");
				} else if (hoursleft < 24) {
					// hours remaining
					return "In " + hoursleft.toFixed(0) + (hoursleft.toFixed(0) == "1" ? " hour" : " hours");
				} else {
					// days remaining
					const daysleft = hoursleft / 24;
					return "In " + daysleft.toFixed(0) + (daysleft.toFixed(0) == "1" ? " day" : " days");
				}
			}

			const hoursleft = differenceInMilliseconds(match.MatchData.UserDate, new Date()) / (1000 * 3600);
			if (hoursleft < 0) {
				return "Soon";
			} else if (hoursleft < 1) {
				// minutes remaining
				const minutesleft = hoursleft * 60;
				return minutesleft.toFixed(0) + (minutesleft.toFixed(0) === "1" ? " min" : " mins");
			} else if (hoursleft < 24) {
				// hours remaining
				return hoursleft.toFixed(0) + (hoursleft.toFixed(0) === "1" ? " hr" : " hrs");
			} else {
				// days remaining
				const daysleft = hoursleft / 24;
				return daysleft.toFixed(0) + (daysleft.toFixed(0) === "1" ? " day" : " days");
			}

		}
		
		return "";
	}

	minUniqueName(match: Match, playerNo: number, forceFirstInitial: boolean = false): string {
		if (!match || !match.MatchData || !match.MatchData.PlayerData) {
			return "";
		}
		const matchingPlayer = playerNo === 1 ? match.MatchData.PlayerData.player1 : match.MatchData.PlayerData.player2;
		if (match.MatchData.PlayerData.player1.nameDetails.last !== match.MatchData.PlayerData.player2.nameDetails.last
			&& !forceFirstInitial) {
			return matchingPlayer.nameDetails.last;
		}

		if (match.MatchData.PlayerData.player1.nameDetails.last !== match.MatchData.PlayerData.player2.nameDetails.last
			|| match.MatchData.PlayerData.player1.nameDetails.first[0] !== match.MatchData.PlayerData.player1.nameDetails.first[0]) {
			return matchingPlayer.nameDetails.first[0] + ". "
				+ matchingPlayer.nameDetails.last;
		}

		return matchingPlayer.nameDetails.first + " "
			+ matchingPlayer.nameDetails.last;
	}

	

	getSportShortName(sportCode: string): string {
		if (SPORTS.some(s => s.code === sportCode.toUpperCase())) {
			return SPORTS.find(s => s.code === sportCode.toUpperCase()).shortName;
		}

		return sportCode;
	}

	trimMatchID(matchID: string): string {
		return matchID.split(/_(.+)/)[1].toLowerCase();
	}

	hasHighlightedTeam(match: Match | ScoreStripEntry): boolean {
		const highlightedTeams = this.localisationService.getLocaleObject().highlightedTeams;
		return match && typeof match.MatchData !== "undefined"
			&& typeof match.MatchData.HomeTeam !== "undefined" && typeof match.MatchData.AwayTeam !== "undefined"
			&& typeof highlightedTeams !== "undefined"
			&& highlightedTeams[match.MatchData.Sport.toUpperCase()]
			&& (highlightedTeams[match.MatchData.Sport.toUpperCase()].includes(match.MatchData.HomeTeam.Abv)
				|| highlightedTeams[match.MatchData.Sport.toUpperCase()].includes(match.MatchData.AwayTeam.Abv));
	}

	/**
	 * Takes in the latitide and longitude of two points, in decimal degrees, and returns an estimated distance between them in metres
	 */
	coordinateDistance(aLat: number, aLon: number, bLat: number, bLon: number): number {
		// method adapted from https://www.movable-type.co.uk/scripts/latlong.html
		const R = 6371e3; // metres
		const aLatRad = aLat * Math.PI / 180; // latitide of point a in radians
		const bLatRad = bLat * Math.PI / 180; // latitide of point b in radians
		const deltaLatRad = (bLat - aLat) * Math.PI / 180; // difference between latitudes in radians
		const deltaLonRad = (bLon - aLon) * Math.PI / 180; // difference between longitudes in radians

		const a = Math.sin(deltaLatRad / 2) * Math.sin(deltaLatRad / 2) +
			Math.cos(aLatRad) * Math.cos(bLatRad) * Math.sin(deltaLonRad / 2) * Math.sin(deltaLonRad / 2);

		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		return R * c;
	}

	isHighlightedMatch(m: Match | ScoreStripEntry): boolean {
		return typeof m.MatchData !== "undefined"
			&& this.localisationService.getLocaleObject().highlightedMatchIDs.includes(m.MatchData.SIMatchID);
	}

	gameDisplaySort(a: Match | ScoreStripEntry, b: Match | ScoreStripEntry, preMatchSportPriority: boolean = false): number {
		if (!a.MatchData || !b.MatchData) {
			return 0;
		}
		// if one game is highlighted and the other isn't, put the highlighted game ahead
		if (this.isHighlightedMatch(a) && !this.isHighlightedMatch(b)) {
			return -1;
		}

		if (!this.isHighlightedMatch(a) && this.isHighlightedMatch(b)) {
			return 1;
		}

		// if one game has a highlighted team in it and the other doesn't, put the game with the highlighted team ahead
		if (this.hasHighlightedTeam(a) && !this.hasHighlightedTeam(b)) {
			return -1;
		}

		if (this.hasHighlightedTeam(b) && !this.hasHighlightedTeam(a)) {
			return 1;
		}

		// if user proximity weighting has been applied in configuration, and the user's IP-derived latitude and longitude are available
		// if (configurationObject.applyUserProximityWeighting && this.userRetrievedLat !== null && this.userRetrievedLon !== null) {

		// 	// calculate distance from each team's location to the user,
		// 	// and check if the distance falls within the threshold to be considered "close"
		// 	const aHomeTeamClose = typeof a.MatchData.HomeTeam !== "undefined" && typeof a.MatchData.HomeTeam.latitude !== "undefined"
		// 		&& typeof a.MatchData.HomeTeam.longitude !== "undefined"
		// 		&& typeof this.userRetrievedLat !== "undefined" && typeof this.userRetrievedLon !== "undefined"
		// 		&& this.coordinateDistance(a.MatchData.HomeTeam.latitude, a.MatchData.HomeTeam.longitude,
		// 			this.userRetrievedLat, this.userRetrievedLon)
		// 			<= this.teamProximityThreshold;
		// 	const aAwayTeamClose = typeof a.MatchData.AwayTeam !== "undefined" && typeof a.MatchData.AwayTeam.latitude !== "undefined"
		// 		&& typeof a.MatchData.AwayTeam.longitude !== "undefined"
		// 		&& typeof this.userRetrievedLat !== "undefined" && typeof this.userRetrievedLon !== "undefined"
		// 		&& this.coordinateDistance(a.MatchData.AwayTeam.latitude, a.MatchData.AwayTeam.longitude,
		// 			this.userRetrievedLat, this.userRetrievedLon)
		// 			<= this.teamProximityThreshold;

		// 	const bHomeTeamClose = typeof b.MatchData.HomeTeam !== "undefined" && typeof b.MatchData.HomeTeam.latitude !== "undefined"
		// 		&& typeof b.MatchData.HomeTeam.longitude !== "undefined"
		// 		&& typeof this.userRetrievedLat !== "undefined" && typeof this.userRetrievedLon !== "undefined"
		// 		&& this.coordinateDistance(b.MatchData.HomeTeam.latitude, b.MatchData.HomeTeam.longitude,
		// 			this.userRetrievedLat, this.userRetrievedLon)
		// 			<= this.teamProximityThreshold;
		// 	const bAwayTeamClose = typeof b.MatchData.AwayTeam !== "undefined" && typeof b.MatchData.AwayTeam.latitude !== "undefined"
		// 		&& typeof b.MatchData.AwayTeam.longitude !== "undefined"
		// 		&& typeof this.userRetrievedLat !== "undefined" && typeof this.userRetrievedLon !== "undefined"
		// 		&& this.coordinateDistance(b.MatchData.AwayTeam.latitude, b.MatchData.AwayTeam.longitude,
		// 			this.userRetrievedLat, this.userRetrievedLon)
		// 			<= this.teamProximityThreshold;

		// 	const aHasCloseTeam = aHomeTeamClose || aAwayTeamClose;
		// 	const bHasCloseTeam = bHomeTeamClose || bAwayTeamClose;

		// 	// if one game has at least one close team, but the other doesn't, put the game with the close team(s) ahead
		// 	if (aHasCloseTeam && !bHasCloseTeam) {
		// 		return -1;
		// 	}

		// 	if (bHasCloseTeam && !aHasCloseTeam) {
		// 		return 1;
		// 	}
		// }

		// if one game is live/paused and the other is not, put the live game ahead
		if ((this.isLivePipe.transform(a) || this.isPausedPipe.transform(a))
			&& (!this.isLivePipe.transform(b) && !this.isPausedPipe.transform(b))) {
			return -1;
		}

		if ((this.isLivePipe.transform(b) || this.isPausedPipe.transform(b))
			&& (!this.isLivePipe.transform(a) && !this.isPausedPipe.transform(a))) {
			return 1;
		}

		// if one game is upcoming and the other is neither live nor upcoming, put the upcoming game first
		if (this.isUpcoming(a) && !this.isLivePipe.transform(b)
			&& !this.isPausedPipe.transform(b) && !this.isUpcoming(b)) {
			return -1;
		}

		if (this.isUpcoming(b) && !this.isLivePipe.transform(a)
			&& !this.isPausedPipe.transform(a) && !this.isUpcoming(a)) {
			return 1;
		}

		// if neither game is live and pre-match sport priority has been turned on:
		if (preMatchSportPriority && !this.isLivePipe.transform(a) && !this.isPausedPipe.transform(a)) {
			const priorityOrder = this.localisationService.getLocaleObject().sportPriorityOrder;

			// if neither match's sport is in the current locale's priority list, skip this logic and go to the next phase
			if (priorityOrder.includes(a.MatchData.Sport) || priorityOrder.includes(b.MatchData.Sport)) {
				// if both matches' sports are in the current locale's priority list, put the one earlier in the priority list ahead
				if (priorityOrder.includes(a.MatchData.Sport)
					&& priorityOrder.includes(b.MatchData.Sport)) {
					return priorityOrder.indexOf(a.MatchData.Sport) - priorityOrder.indexOf(b.MatchData.Sport);
				}

				// if exactly one of the two matches' sports is in the current locale's priority list, put the one that's there ahead
				if (priorityOrder.includes(a.MatchData.Sport)) {
					return -1;
				}

				if (priorityOrder.includes(b.MatchData.Sport)) {
					return 1;
				}
			}
		}

		// if both games are live:
		if (this.isLivePipe.transform(a) || this.isPausedPipe.transform(a)) {
			const arbitrarySportOrder = ["NFL", "NBA", "MLB", "NHL", "EPL", "ESP"];

			// if games have different sports and one or both of them is in the priority list, put the one first in the list ahead
			if (a.MatchData.Sport !== b.MatchData.Sport) {
				if (arbitrarySportOrder.includes(a.MatchData.Sport) || arbitrarySportOrder.includes(b.MatchData.Sport)) {
					if (!arbitrarySportOrder.includes(a.MatchData.Sport)) {
						return 1;
					}

					if (!arbitrarySportOrder.includes(b.MatchData.Sport)) {
						return -1;
					}

					return arbitrarySportOrder.indexOf(a.MatchData.Sport) - arbitrarySportOrder.indexOf(b.MatchData.Sport);
				}
			}
		}


		// otherwise, put the game with an earlier start time first
		return compareAsc(a.MatchData.UserDate, b.MatchData.UserDate);
	}

	getGeoData(/*configurationObject: BlockConfigurationObject*/): Promise<void> {
		return new Promise((resolve) => {
			// if (configurationObject.user) {
			// 	if (configurationObject.user.country) {
			// 		this.geoCountry = configurationObject.user.country;
			// 	}

			// 	if (configurationObject.user.state) {
			// 		this.geoRegion = configurationObject.user.country;
			// 	}

			// 	if (configurationObject.user.latitude && configurationObject.user.longitude) {
			// 		this.userRetrievedLat = configurationObject.user.latitude;
			// 		this.userRetrievedLon = configurationObject.user.longitude;
			// 	}
			// }


			// // if bookmaker country blacklist exists
			// if (typeof configurationObject.bookmakerCountryBlacklist !== "undefined") {
			// 	// if user's country is in bookmaker country blacklist
			// 	if (this.geoCountry && configurationObject.bookmakerCountryBlacklist.includes(this.geoCountry)) {

			// 		// if bookmaker region whitelist doesn't exist, or doesn't have a list for the user's country,
			// 		// or user's region isn't available, or the user's region isn't in the list for the user's country
			// 		if (typeof configurationObject.bookmakerRegionWhitelist === "undefined"
			// 			|| typeof configurationObject.bookmakerRegionWhitelist[this.geoCountry] === "undefined"
			// 			|| !this.geoRegion
			// 			|| !configurationObject.bookmakerRegionWhitelist[this.geoCountry].includes(this.geoRegion)) {

			// 			// remove the bookmaker theme from the widget
			// 			configurationObject.bookmakerTheme = "";
			// 		}
			// 	}
			// } else
			// // if bookmaker country whitelist exists
			// if (typeof configurationObject.bookmakerCountryWhitelist !== "undefined") {
			// 	// if user's country is not in bookmaker country whitelist
			// 	if (!configurationObject.bookmakerCountryWhitelist.includes(this.geoCountry)) {
			// 		// remove bookmaker theme
			// 		configurationObject.bookmakerTheme = "";
			// 	} else


			// 	// if bookmaker region blacklist exists, and user's country has record in bookmaker region blacklist,
			// 	// and user's region is available, and user's region is in list for user's country
			// 	if (typeof configurationObject.bookmakerRegionBlacklist !== "undefined"
			// 		&& typeof configurationObject.bookmakerRegionBlacklist[this.geoCountry] !== "undefined"
			// 		&& this.geoRegion
			// 		&& configurationObject.bookmakerRegionBlacklist[this.geoCountry].includes(this.geoRegion)) {

			// 		// remove bookmaker theme
			// 		configurationObject.bookmakerTheme = "";
			// 	}
			// }
			resolve();
		});
	}

	convertBallsToOvers(balls: number): string {
		const fullOvers = Math.floor(balls / 6);
		const overBalls = balls % 6;
		return `${fullOvers}.${overBalls}`;
	}

	matchFitsFilter(sport: string, maxHoursAhead?: number,
		maxHoursBehind?: number): (match: Match | ScoreStripEntry) => boolean {
		return (match: Match): boolean => match && typeof match.MatchData !== "undefined"
			&& typeof match.MatchData.Sport !== "undefined" && sport.length > 0
			&& (match.MatchData.Sport.toUpperCase() === sport.toUpperCase() || sport.toUpperCase() === "ALL")
			&& (this.matchFitsTimeFilter(match, maxHoursAhead, maxHoursBehind)
				|| this.isHighlightedMatch(match));
	}

	matchFitsTimeFilter(match: Match, maxHoursAhead?: number,
		maxHoursBehind?: number): boolean {
		return this.isLivePipe.transform(match)
		|| this.isPausedPipe.transform(match)
		// return matchService.matchIsUpcoming(match) && differenceInHours(new Date(), match.MatchData.UserDate) > -72)
		|| (match.MatchData && this.isUpcoming(match)
			&& (!maxHoursAhead || differenceInHours(new Date(), match.MatchData.UserDate) > -maxHoursAhead))
		// || (matchService.matchIsOver(match) && differenceInHours(new Date(), match.MatchData.UserDate) < 24);
		|| (match.MatchData && this.isOverPipe.transform(match)
			&& (!maxHoursBehind || differenceInHours(new Date(), match.MatchData.UserDate) < maxHoursBehind));
	}

	lostSet(match: Match, playerNo: number, setNo: number): boolean {
		if (match && match.MatchData && match.LiveData && match.LiveData.player1Sets && match.LiveData.player2Sets) {
			let p1Set: number; let p2Set: number;
			switch (setNo) {
				case 1:
					p1Set = match.LiveData.p1Set1 as number;
					p2Set = match.LiveData.p2Set1 as number;
					break;
				case 2:
					p1Set = match.LiveData.p1Set2 as number;
					p2Set = match.LiveData.p2Set2 as number;
					break;
				case 3:
					p1Set = match.LiveData.p1Set3 as number;
					p2Set = match.LiveData.p2Set3 as number;
					break;
				case 4:
					p1Set = match.LiveData.p1Set4 as number;
					p2Set = match.LiveData.p2Set4 as number;
					break;
				case 5:
					p1Set = match.LiveData.p1Set5 as number;
					p2Set = match.LiveData.p2Set5 as number;
					break;
				default:
					return false;
			}
			const trailingSet = playerNo === 1 ? p1Set < p2Set : p2Set < p1Set;
			const setOver = (match.LiveData.player1Sets + match.LiveData.player2Sets) >= setNo;

			return trailingSet && setOver;
		}

		return false;

	}

	lostTennisMatch(playerNo: number, match: Match): boolean {
		if (!this.isOverPipe.transform(match)) {
			return false;
		}

		if (!match.LiveData || typeof match.LiveData.player1Sets !== "number" || typeof match.LiveData.player2Sets !== "number") {
			return false;
		}

		if (match.LiveData.status === "retirement") {
			if (playerNo === 1) {
				return match.LiveData.player1WinProb === 0;
			} else {
				return match.LiveData.player2WinProb === 0;
			}
		}

		if (playerNo === 1) {
			return match.LiveData.player1Sets < match.LiveData.player2Sets;
		} else {
			return match.LiveData.player2Sets < match.LiveData.player1Sets;
		}
	}

	formattedDate(date: Date): string {
		if (date) {
			// if date is exactly on the hour (local time)
			if (getMinutes(date) === 0) {
				// remove the ":00" from the time section
				return format(date, "ha, MMM d");

			}

			return format(date, "h:mma, MMM d");
		}

		return "";

	}

	formattedDateAlt(date: Date): string {
		if (date) {
			// if date is exactly on the hour (local time)
			if (getMinutes(date) === 0) {
				// remove the ":00" from the time section
				return format(date, "MMM d, ha");
			}

			return format(date, "MMM d, h:mma");
		}


		return "";
	}

	teamInRedZone(data: MatchLiveData): boolean {
		return typeof data !== "undefined" && typeof data.actionSideSI !== "undefined" && typeof data.actionYardLine !== "undefined"
			&& typeof data.teamWithBallSI !== "undefined" && data.actionYardLine <= 20 && data.actionSideSI !== data.teamWithBallSI;
	}

	fieldDiagramBallLocation(match: Match): number {
		if (match.MatchData && match.MatchData.HomeTeam && match.LiveData && match.LiveData.actionYardLine !== null
			&& match.LiveData.actionSideSI !== null && typeof match.LiveData.actionYardLine !== "undefined"
			&& typeof match.LiveData.actionSideSI !== "undefined") {
			const startingPoint = 2132;
			const displacement = (50 - match.LiveData.actionYardLine) * 36;
			if (match.LiveData.actionSideSI === match.MatchData.HomeTeam.Abv) {
				return startingPoint - displacement;
			} else {
				return startingPoint + displacement;
			}
		} else {
			return -500;
		}
	}

	

	downAndDistanceSummary(data: MatchLiveData): string {
		if (data.actionDown === null || data.yardsToFirstDown === null
			|| typeof data.actionDown === "undefined" || typeof data.yardsToFirstDown === "undefined") {

			if (data.status === "completed" || data.status === "ended" || data.status === "retirement") {
				return "-";
			} else {
				return "-";
			}
		}
		const down = this.nthFilter.transform(data.actionDown);

		return `${down} and ${data.yardsToFirstDown}`;
	}

	liveStatusString(match: Match | ScoreStripEntry): string {
		const data = match.LiveData;

		if (data && match.MatchData) {
			if (data.status === "halftime") {
				return "Halftime";
			}

			if (match.MatchData.Sport.toLowerCase() === "ten") {
				if (typeof data.player1Sets === "number" && typeof data.player2Sets === "number") {
					const currentSet = data.player1Sets + data.player2Sets + 1;
					return `${this.nthFilter.transform(currentSet)} Set`;
				}
				return "";

			}

			if (match.MatchData.Sport.toLowerCase() === "afl") {
				if (data.period && (data.periodSeconds || data.periodSeconds === 0)) {
					return `Q${data.period} ${Math.floor(data.periodSeconds / 60)}:${
						data.periodSeconds % 60 < 10 ? "0" : ""
					}${
						data.periodSeconds % 60
					}`;
				}

			}

			if (["nrl", "soo"].includes(match.MatchData.Sport.toLowerCase())) {
				if (data.minsGone) {
					let periodAbv;
					if (data.minsGone <= 40) {
						periodAbv = "H1";
					} else if (data.minsGone <= 80) {
						periodAbv = "H2";
					} else {
						periodAbv = "ET";
					}
					return `${periodAbv} ${Math.floor(data.minsGone)}:${
						Math.floor((data.minsGone % 1) * 60) < 10 ? "0" : ""
					}${
						Math.floor((data.minsGone % 1) * 60)
					}`;
				}
				return "";
			}

			if (match.MatchData.Sport.toLowerCase() === "cfb") {
				if (data.period && (data.periodSeconds || data.periodSeconds === 0)) {
					if (data.period > 4) {
						// Display just the overtime period
						return `OT${data.period - 4}`;
					}

					const quarterSecondsLeft = 900 - data.periodSeconds;

					return `${this.nthFilter.transform(data.period)} ` +
						`${Math.floor(quarterSecondsLeft / 60)}:${quarterSecondsLeft % 60 < 10 ? "0" : ""}${quarterSecondsLeft % 60}`;
				}
				return "";
			}

			if (match.MatchData.Sport.toLowerCase() === "nfl") {
				if (data.period && (data.periodSeconds || data.periodSeconds === 0)) {
					let periodAbv;
					if (data.period > 4) {
						periodAbv = `${data.period === 5 ? "" : data.period - 4}OT`;
					} else {
						periodAbv = `${this.nthFilter.transform(data.period)}`;
					}

					// 15 minutes (900 seconds) for regulation quarters and postseason overtimes; 10 minutes (600 seconds) for regular season overtimes
					// TODO should be 900 for all periods in postseason, but indicator is not available yet
					const periodLength = data.period > 4 ? 600 : 900;
					const quarterSecondsLeft = periodLength - data.periodSeconds;

					return `${periodAbv} ` +
						`${Math.floor(quarterSecondsLeft / 60)}:${quarterSecondsLeft % 60 < 10 ? "0" : ""}${quarterSecondsLeft % 60}`;
				}

				return "";
			}

			if (match.MatchData.Sport.toLowerCase() === "nba") {
				if (data.period && (data.periodSeconds || data.periodSeconds === 0)) {
					let periodAbv;
					if (data.period > 4) {
						periodAbv = `${data.period === 5 ? "" : data.period - 4}OT`;
					} else {
						periodAbv = `${this.nthFilter.transform(data.period)}`;
					}

					const periodSecondsLeft = (data.period > 4 ? 300 : 720) - data.periodSeconds;

					return `${periodAbv} ` +
						`${Math.floor(periodSecondsLeft / 60)}:${periodSecondsLeft % 60 < 10 ? "0" : ""}${periodSecondsLeft % 60}`;
				}
				return "";
			}

			if (match.MatchData.Sport.toLowerCase() === "wnba") {
				if (data.period && (data.periodSeconds || data.periodSeconds === 0)) {
					let periodAbv;
					if (data.period > 4) {
						periodAbv = `${data.period === 5 ? "" : data.period - 4}OT`;
					} else {
						periodAbv = `${this.nthFilter.transform(data.period)}`;
					}

					const periodSecondsLeft = (data.period > 4 ? 300 : 600) - data.periodSeconds;

					return `${periodAbv} ` +
						`${Math.floor(periodSecondsLeft / 60)}:${periodSecondsLeft % 60 < 10 ? "0" : ""}${periodSecondsLeft % 60}`;
				}
				return "";
			}

			if (match.MatchData.Sport.toLowerCase() === "cbb") {
				if (data.period && (data.periodSeconds || data.periodSeconds === 0)) {
					let periodAbv;
					if (data.period > 2) {
						periodAbv = `${data.period === 3 ? "" : data.period - 2}OT`;
					} else {
						periodAbv = `${this.nthFilter.transform(data.period)}`;
					}

					const periodSecondsLeft = (data.period > 2 ? 300 : 1200) - data.periodSeconds;

					return `${periodAbv} ${Math.floor(periodSecondsLeft / 60)}:` +
						`${periodSecondsLeft % 60 < 10 ? "0" : ""}${periodSecondsLeft % 60}`;

				}

				return "";
			}

			if (match.MatchData.Sport.toLowerCase() === "mlb") {
				if (data.inning && data.inningHalf) {
					return `${data.inningHalf === "B" ? "Bot" : "Top"} ${this.nthFilter.transform(data.inning)}`;
				}
				return "";
			}

			if (match.MatchData.Sport.toLowerCase() === "nhl") {
				if (data.period === 5 && data.match_type === "REG") {
					return "Shootout";
				}
				let periodAbv: string;
				if (data.period) {
					if (data.period && data.period > 3) {
						periodAbv = `${data.period === 4 ? "" : data.period - 3}OT`;
					} else {
						periodAbv = `${this.nthFilter.transform(data.period)}`;
					}
				} else {
					periodAbv = "";
				}


				// const quarterSecondsLeft = 1200 - data.periodSeconds;

				return `${periodAbv} ${data.clock}`;
			}

			if (this.generalService.isSoccer(match.MatchData.Sport)) {
				if (data.clock) {
					return data.clock;
				}

				return Math.floor(data.minsGone) + "'";
			}


			// TODO other sports
			return "";
		}

		return "";
	}

	cricketScoreString(match: Match, team: "home" | "away"): string {
		if (team === "home") {
			if (typeof match.LiveData.homeWickets == "undefined" || typeof match.LiveData.homeRuns == "undefined"
				|| match.LiveData.homeRuns + match.LiveData.homeWickets + match.LiveData.homeBalls === 0) {
				return "";
			}
			return `${match.LiveData.homeWickets == 10 ? "" : `${match.LiveData.homeWickets}/`}${match.LiveData.homeRuns}`;
		}

		if (typeof match.LiveData.awayWickets == "undefined" || typeof match.LiveData.awayRuns == "undefined"
			|| match.LiveData.awayRuns + match.LiveData.awayWickets + match.LiveData.awayBalls === 0) {
			return "";
		}
		return `${match.LiveData.awayWickets == 10 ? "" : `${match.LiveData.awayWickets}/`}${match.LiveData.awayRuns}`;
		
	}
}
