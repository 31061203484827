import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bookIcon',
    standalone: true
})
export class BookIconPipe implements PipeTransform {

  transform(bookName: string): string {
    switch (bookName?.toLowerCase()) {
      case "betstreet":
        return "https://content-blocks-node.azurewebsites.net/static/assets/betstreet-icon.png";
      case "fanduel":
        return "https://content-blocks-node.azurewebsites.net/static/assets/fanduel-icon.svg";
      case "sugarhouse":
        return "https://content-blocks-node.azurewebsites.net/static/assets/sugarhouse-icon.svg";
      case "pointsbet":
        return "https://content-blocks-node.azurewebsites.net/static/assets/pointsbet-icon.svg";
      case "william_hill":
        return "https://content-blocks-node.azurewebsites.net/static/assets/william-hill-icon.svg";
      case "draftkings":
        return "https://content-blocks-node.azurewebsites.net/static/assets/draftkings-icon.svg";
      case "wynn":
        return "https://cdn.ciphersports.io/images/bookmaker-images/wynnbet-icon.png";
      case "betmgm":
        return "https://content-blocks-node.azurewebsites.net/static/assets/betmgm-icon.svg";
      case "bet_rivers_in":
      case "bet_rivers_va":
      case "bet_rivers_co":
        return "https://content-blocks-node.azurewebsites.net/static/assets/bet-rivers-icon.svg";
      case "bet365":
      case "bet_365":
        return "https://content-blocks-node.azurewebsites.net/static/assets/bet365-icon.svg";
      case "betway":
        return "https://content-blocks-node.azurewebsites.net/static/assets/betway-icon.svg";
      case "unibet":
        return "https://content-blocks-node.azurewebsites.net/static/assets/unibet-icon.svg";
      case "tipico":
        return "https://content-blocks-node.azurewebsites.net/static/assets/tipico-icon.svg";
      case "twinspires":
        return "https://content-blocks-node.azurewebsites.net/static/assets/twinspires-icon.png";
      default:
        return ""
    }
  }

}
