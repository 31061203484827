import { Component, Input, ViewEncapsulation } from "@angular/core";
import { faCaretLeft } from "@fortawesome/pro-solid-svg-icons";
import { BetService } from "src/app/bet.service";
import { GeneralService } from "src/app/general.service";
import { Match } from "src/app/match.model";
import { MatchService } from "src/app/match.service";
import { BookIconPipe } from "../../book-pipes/book-icon.pipe";
import { BookForegroundColourPipe } from "../../book-pipes/book-foreground-colour.pipe";
import { BookBackgroundColourPipe } from "../../book-pipes/book-background-colour.pipe";
import { IsPausedPipe } from "../../match-pipes/is-paused.pipe";
import { IsOverPipe } from "../../match-pipes/is-over.pipe";
import { IsLivePipe } from "../../match-pipes/is-live.pipe";
import { IsPreMatchOnlyPipe } from "../../match-pipes/is-pre-match-only.pipe";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgIf, AsyncPipe, LowerCasePipe, DecimalPipe } from "@angular/common";

@Component({
    selector: "app-mini-match-tile",
    templateUrl: "./mini-match-tile.component.html",
    styleUrls: ["./mini-match-tile.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgIf, FontAwesomeModule, AsyncPipe, LowerCasePipe, DecimalPipe, IsPreMatchOnlyPipe, IsLivePipe, IsOverPipe, IsPausedPipe, BookBackgroundColourPipe, BookForegroundColourPipe, BookIconPipe]
})
export class MiniMatchTileComponent {

  @Input() match: Match;

  faCaretLeft = faCaretLeft;

  constructor(
    public matchService: MatchService,
    public generalService: GeneralService,
    public betService: BetService
  ) { }

}