import { Component, OnInit } from '@angular/core';
import { MetaSetterService } from '../meta-setter.service';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-payment-cancel-page',
    templateUrl: './payment-cancel-page.component.html',
    styleUrls: ['./payment-cancel-page.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class PaymentCancelPageComponent implements OnInit{

    constructor(
        private metaSetter: MetaSetterService,
    ) {}

    ngOnInit() {
        this.metaSetter.setMeta({
            title: "Dimers",
            description: "No more watching from the sidelines. Dimers gives sports fans the news, tools & analysis to Make the Play.",
            canonicalURL: "https://www.dimers.com/payment-cancel",
            noIndex:true
        });
    }

}
