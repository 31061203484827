import { Sport } from "./sport.model";

export const SPORTS: Array<Sport> = [
	// {
	// 	code: "SOO",
	// 	shortName: "Origin",
	// 	fullName: "State of Origin",
	// 	active: false,
	// 	hasFutures: false,
	// 	valueSummaryEligible: false,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	navigablePages: [
	// 		{name: "Match Page", path: "bet-hub/soo/schedule/2021_2_QLD_NSW", pageCode: "match"}}
	// 	],
	// 	fixtureType: "special",
	// 	hasLiveMatchPredictions: false,
	// 	isSoccer: false,
	// 	usesOfficialLogos: true,
	// 	showInScoreStrip: true,
	// 	jerseyFileKey: "Abv",
	// },
	// {
	// 	code: "EURO",
	// 	shortName: "Euro 2020",
	// 	fullName: "Euro 2020",
	// 	active: true,
	// 	isSoccer: true,
	// 	hasFutures: true,
	// 	fixtureType: "daily",
	// 	valueSummaryEligible: true,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	hasLiveMatchPredictions: true,
	// 	usesOfficialLogos: true,
	// 	jerseyFileKey: "Abv",
	// 	showInScoreStrip: true,
	// 	jerseyFileExtension: "png",
	// 	navigablePages: [
	// 		{name: "Hub", path: "/euro", pageCode: "hub"},
	// 		{name: "Fixtures", path: "/bet-hub/euro/schedule", pageCode: "schedule"},
	// 		{name: "Futures", path: "/futures/euro", pageCode: "futures"},
	// 		{name: "Simulator", path: "/euro/simulator", pageCode: "simulator"},
	// 	],
	// 	hideFromMainNav: false,
	// },
	// {
	// 	code: "AFL",
	// 	shortName: "AFL",
	// 	fullName: "Australian Football League",
	// 	active: true,
	// 	fixtureType: "weekly",
	// 	isSoccer: false,
	// 	hasFutures: true,
	// 	valueSummaryEligible: true,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	hasLiveMatchPredictions: true,
	// 	usesOfficialLogos: true,
	// 	jerseyFileKey: "Abv",
	// 	showInScoreStrip: true,
	// 	navigablePages: [
	// 		{name: "Hub", path: "/afl", pageCode: "hub"},
	// 		{name: "Fixtures", path: "/bet-hub/afl/schedule", pageCode: "schedule"},
	// 		{name: "Futures", path: "/futures/afl", pageCode: "futures"},
	// 		{name: "Player Ratings", path: "/afl/player-ratings", pageCode: "player-ratings"},
	// 		{name: "Shot Charting", path: "/afl/shot-charting", pageCode: "shot-charting"},
	// 		{name: "Player DNA", path: "/afl/player-dna", pageCode: "player-dna"},
	// 		{name: "Schedule Difficulty", shortName: "Sched Diff", path: "/afl/sos", pageCode: "sos"},
	// 		{name: "Tipping", path: "/afl/tipping", pageCode: "tipping"},
	// 		// {name: "Sackometer", path: "/afl/sackometer", pageCode: "sackometer"},
	// 		// {name: "Brownlow 2020", path: "/afl/brownlow", pageCode: "brownlow"},
	// 	]
	// },
	// {
	// 	code: "NRL",
	// 	shortName: "NRL",
	// 	fullName: "National Rugby League",
	// 	active: true,
	// 	isSoccer: false,
	// 	hasFutures: true,
	// 	fixtureType: "weekly",
	// 	valueSummaryEligible: true,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	hasLiveMatchPredictions: true,
	// 	usesOfficialLogos: true,
	// 	jerseyFileKey: "Abv",
	// 	showInScoreStrip: true,
	// 	navigablePages: [
	// 		{name: "Hub", path: "/nrl", pageCode: "hub"},
	// 		{name: "Fixtures", path: "/bet-hub/nrl/schedule", pageCode: "schedule"},
	// 		{name: "Futures", path: "/futures/nrl", pageCode: "futures"},
	// 		{name: "True Kicker", path: "/nrl/true-kicker", pageCode: "true-kicker"},
	// 		{name: "Try Location Analysis", shortName: "Try Locations", path: "/nrl/left-right", pageCode: "left-right"},
	// 		{name: "Schedule Difficulty", shortName: "Sched Diff", path: "/nrl/sos", pageCode: "sos"},
	// 		{name: "Tipping", path: "/nrl/tipping", pageCode: "tipping"},
	// 	]
	// },
	// {
	// 	code: "AFLW",
	// 	shortName: "AFLW",
	// 	fullName: "AFL Women's",
	// 	active: false,
	// 	fixtureType: "weekly",
	// 	isSoccer: false,
	// 	hasFutures: false,
	// 	valueSummaryEligible: true,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	hasLiveMatchPredictions: false,
	// 	usesOfficialLogos: true,
	// 	jerseyFileKey: "Abv",
	// 	showInScoreStrip: true,
	// 	navigablePages: [
	// 		{name: "Hub", path: "/aflw", pageCode: "hub"},
	// 		{name: "Fixtures", path: "/bet-hub/aflw/schedule", pageCode: "schedule"},
	// 		{name: "Tipping", path: "/aflw/tipping", pageCode: "tipping"},
	// 	]
	// },
	{
		code: "NFL",
		shortName: "NFL",
		fullName: "National Football League",
		active: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		liveBetsEligible: true,
		hasLiveMatchPredictions: true,
		hasFutures: true,
		isSoccer: false,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/nfl/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/nfl", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/nfl", pageCode: "best-props"},
			{name: "Picks", path: "/nfl/picks", pageCode: "picks"},
			{name: "Trends", path: "/best-trends/nfl", pageCode: "trends"},
			{name: "Parlays", path: "/parlay-picker/nfl", pageCode: "parlay-picker"},
			{name: "Live Bets", path: "/live-bets/nfl", pageCode: "live-bets"},
			{name: "Futures", path: "/futures/nfl", pageCode: "futures"},
			{name: "Odds", path: "/nfl/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "nfl"}, pageCode: "news"},
			{name: "Super Bowl", path: "/super-bowl", pageCode: "super-bowl"},
		]
	},
	{
		code: "CFB",
		shortName: "College Football",
		fullName: "College Football",
		active: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		hasFutures: true,
		isSoccer: false,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/cfb/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/cfb", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/cfb", pageCode: "best-props"},
			{name: "Picks", path: "/cfb/picks", pageCode: "picks"},
			{name: "Best Trends", path: "/best-trends/cfb", pageCode: "trends"},
			{name: "Parlays", path: "/parlay-picker/cfb", pageCode: "parlay-picker"},
			{name: "Futures", path: "/futures/cfb", pageCode: "futures"},
			{name: "Odds", path: "/cfb/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "cfb"}, pageCode: "news"}
		]
	},
	{
		code: "SWC",
		shortName: "FIFA World Cup",
		fullName: "FIFA World Cup",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "special",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: false,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		jerseyFileExtension: "png",
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/swc/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/swc", pageCode: "best-bets"},
			{name: "Picks", path: "/swc/picks", pageCode: "picks"},
			{name: "Futures", path: "/futures/swc", pageCode: "futures"},
			// {name: "Odds", path: "/swc/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "swc"}, pageCode: "news"},
			{name: "Preview", path: "/bet-hub/swc/preview", pageCode: "preview"},
			{name: "Simulator", path: "/swc/simulator", pageCode: "simulator"}
		]
	},
	{
		code: "CBB",
		shortName: "College Basketball",
		fullName: "College Basketball",
		active: true,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/cbb/schedule", pageCode: "schedule"},
			// {name: "March Madness", path: "/march-madness", pageCode: "march-madness"},
			{name: "Best Bets", path: "/best-bets/cbb", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/cbb", pageCode: "best-props"},
			{name: "Picks", path: "/cbb/picks", pageCode: "picks"},
			{name: "Trends", path: "/best-trends/cbb", pageCode: "trends"},
			{name: "Futures", path: "/futures/cbb", pageCode: "futures"},
			{name: "Odds", path: "/cbb/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "cbb"}, pageCode: "news"},
			// {name: "Simulator", path: "/cbb/simulator", pageCode: "simulator"}
		]
	},
	{
		code: "NBA",
		shortName: "NBA",
		fullName: "National Basketball Association",
		active: true,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		hasFutures: true,
		isSoccer: false,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/nba/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/nba", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/nba", pageCode: "best-props"},
			{name: "Picks", path: "/nba/picks", pageCode: "picks"},
			{name: "Trends", path: "/best-trends/nba", pageCode: "trends"},
			// {name: "Today's Promos", path: "/todays-promos/nba", pageCode: "todays-promos"},
			{name: "Parlays", path: "/parlay-picker/nba", pageCode: "parlay-picker"},
			{name: "Futures", path: "/futures/nba", pageCode: "futures"},
			{name: "Odds", path: "/nba/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "nba"}, pageCode: "news"},
			// {name: "Simulator", path: "/nba/simulator", pageCode: "simulator"},
		]
	},
	{
		code: "WNBA",
		shortName: "WNBA",
		fullName: "Women's National Basketball Association",
		active: false,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		hasFutures: true,
		isSoccer: false,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/wnba/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/wnba", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/wnba", pageCode: "best-props"},
			{name: "Picks", path: "/wnba/picks", pageCode: "picks"},
			{name: "Trends", path: "/best-trends/wnba", pageCode: "trends"},
			// {name: "Today's Promos", path: "/todays-promos/wnba", pageCode: "todays-promos"},
			{name: "Parlays", path: "/parlay-picker/wnba", pageCode: "parlay-picker"},
			{name: "Futures", path: "/futures/wnba", pageCode: "futures"},
			{name: "Odds", path: "/wnba/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "wnba"}, pageCode: "news"},
			// {name: "Simulator", path: "/wnba/simulator", pageCode: "simulator"},
		]
	},
	{
		code: "MLB",
		shortName: "MLB",
		fullName: "Major League Baseball",
		active: false,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/mlb/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/mlb", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/mlb", pageCode: "best-props"},
			{name: "Picks", path: "/mlb/picks", pageCode: "picks"},
			{name: "Trends", path: "/best-trends/mlb", pageCode: "trends"},
			{name: "Parlays", path: "/parlay-picker/mlb", pageCode: "parlay-picker"},
			{name: "Futures", path: "/futures/mlb", pageCode: "futures"},
			{name: "Odds", path: "/mlb/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "mlb"}, pageCode: "news"}
		]
	},
	{
		code: "NHL",
		shortName: "NHL",
		fullName: "National Hockey League",
		active: true,
		isSoccer: false,
		hasFutures: true,
		fixtureType: "daily",
		valueSummaryEligible: true,
		propsValueSummaryEligible: true,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/nhl/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/nhl", pageCode: "best-bets"},
			{name: "Best Props", path: "/best-props/nhl", pageCode: "best-props"},
			{name: "Picks", path: "/nhl/picks", pageCode: "picks"},
            {name: "Trends", path: "/best-trends/nhl", pageCode: "trends"},
			{name: "Parlays", path: "/parlay-picker/nhl", pageCode: "parlay-picker"},
			{name: "Futures", path: "/futures/nhl", pageCode: "futures"},
			{name: "Odds", path: "/nhl/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "nhl"}, pageCode: "news"}
		]
	},
	{
		code: "TEN",
		shortName: "Tennis",
		fullName: "Tennis",
		active: true,
		isSoccer: false,
		hasFutures: false,
		fixtureType: "special",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: false,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/ten/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/ten", pageCode: "best-bets"},
			{name: "Picks", path: "/ten/picks", pageCode: "picks"},
			{name: "News", path: "/news", queryParams: {sport: "ten"}, pageCode: "news"},
			{name: "Rankings", path: "/bet-hub/ten/rankings", pageCode: "rankings"},
			// {name: "Tournament Results", path: "/ten/results"},
			// {name: "Men's Simulator", shortName: "M Simulator", path: "/ten/simulator", queryParams: {tid: "2559"}},
			// {name: "Women's Simulator", shortName: "W Simulator", path: "/ten/simulator", queryParams: {tid: "2555"}},
			// {name: "Men's Futures", shortName: "M Futures", path: "/futures/ten", queryParams: {tid: "2591"}},
			// {name: "Women's Futures", shortName: "W Futures", path: "/futures/ten", queryParams: {tid: "2595"}}
		]
	},
	{
		code: "GOLF",
		shortName: "Golf",
		fullName: "Golf",
		active: true,
		fixtureType: "special",
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: false,
		usesOfficialLogos: true,
		isSoccer: false,
		hasFutures: false,
		jerseyFileKey: "Abv",
		showInScoreStrip: false,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/golf/next", pageCode: "tournament"},
			{name: "News", path: "/news", queryParams: {sport: "golf"}, pageCode: "news"},
			{name: "Rankings", path: "/bet-hub/golf/rankings", pageCode: "rankings"},
			{name: "H2H Tool", path: "/bet-hub/golf/h2h", queryParams: {tourn: "next"}, pageCode: "h2h"},
		]
	},
	{
		code: "EPL",
		shortName: "Premier League",
		fullName: "English Premier League",
		active: true,
		isSoccer: true,
		hasFutures: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/epl/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/epl", pageCode: "best-bets"},
			{name: "Picks", path: "/epl/picks", pageCode: "picks"},
			{name: "Futures", path: "/futures/epl", pageCode: "futures"},
			{name: "Odds", path: "/epl/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "epl"}, pageCode: "news"},
		]
	},
	{
		code: "ESP",
		shortName: "La Liga",
		fullName: "La Liga",
		active: true,
		isSoccer: true,
		hasFutures: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/esp/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/esp", pageCode: "best-bets"},
			{name: "Picks", path: "/esp/picks", pageCode: "picks"},
			{name: "Futures", path: "/futures/esp", pageCode: "futures"},
			{name: "Odds", path: "/esp/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "esp"}, pageCode: "news"},
		]
	},
	{
		code: "SLC",
		shortName: "Leagues Cup",
		fullName: "Leagues Cup",
		active: false,
		isSoccer: true,
		hasFutures: true,
		fixtureType: "special",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		jerseyFileExtension: "png",
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/slc/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/slc", pageCode: "best-bets"},
			{name: "Picks", path: "/slc/picks", pageCode: "picks"},
			{name: "Futures", path: "/futures/slc", pageCode: "futures"},
			// {name: "Odds", path: "/slc/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "slc"}, pageCode: "news"},
		],
        hideFromMainNav: true,
	},
	{
		code: "MLS",
		shortName: "MLS",
		fullName: "Major League Soccer",
		active: true,
		isSoccer: true,
		hasFutures: true,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/mls/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/mls", pageCode: "best-bets"},
			{name: "Picks", path: "/mls/picks", pageCode: "picks"},
			{name: "Futures", path: "/futures/mls", pageCode: "futures"},
			{name: "Odds", path: "/mls/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "mls"}, pageCode: "news"},
		]
	},
	{
		code: "BUN",
		shortName: "Bundesliga",
		fullName: "Bundesliga",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "weekly",
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: false,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Odds", path: "/bun/best-odds", pageCode: "best-odds"},
		]
	},
	{
		code: "LUN",
		shortName: "Ligue 1",
		fullName: "Ligue 1",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "weekly",
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: false,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Odds", path: "/lun/best-odds", pageCode: "best-odds"},
		]
	},
	{
		code: "SRA",
		shortName: "Serie A",
		fullName: "Serie A",
		active: false,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "weekly",
		valueSummaryEligible: false,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: false,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		navigablePages: [
			{name: "Odds", path: "/sra/best-odds", pageCode: "best-odds"},
		]
	},
	{
		code: "LMX",
		shortName: "Liga MX",
		fullName: "Liga MX",
		active: true,
		isSoccer: true,
		hasFutures: false,
		fixtureType: "weekly",
		valueSummaryEligible: true,
		propsValueSummaryEligible: false,
		liveBetsEligible: false,
		hasLiveMatchPredictions: true,
		usesOfficialLogos: true,
		jerseyFileKey: "Abv",
		showInScoreStrip: true,
		jerseyFileExtension: "png",
		navigablePages: [
			{name: "Predictions", path: "/bet-hub/lmx/schedule", pageCode: "schedule"},
			{name: "Best Bets", path: "/best-bets/lmx", pageCode: "best-bets"},
			{name: "Picks", path: "/lmx/picks", pageCode: "picks"},
			// {name: "Futures", path: "/futures/lmx", pageCode: "futures"},
			{name: "Odds", path: "/lmx/best-odds", pageCode: "best-odds"},
			{name: "News", path: "/news", queryParams: {sport: "lmx"}, pageCode: "news"},
		]
	},
	// 	code: "ALG",
	// 	shortName: "A-League",
	// 	fullName: "A-League",
	// 	active: false,
	// 	isSoccer: true,
	// 	hasFutures: false,
	// 	fixtureType: "weekly",
	// 	valueSummaryEligible: true,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	hasLiveMatchPredictions: false,
	// 	usesOfficialLogos: true,
	// 	jerseyFileKey: "Abv",
	// 	showInScoreStrip: true,
	// 	navigablePages: [
	// 		{name: "Hub", path: "/alg", pageCode: "hub"},
	// 		{name: "Fixtures", path: "/bet-hub/alg/schedule", pageCode: "schedule"},
	// 		{name: "Futures", path: "/futures/alg", pageCode: "futures"},
	// 		{name: "Tipping", path: "/alg/tipping", pageCode: "tipping"},
	// 	]
	// },
	// {
	// 	code: "SRU",
	// 	shortName: "Super Rugby",
	// 	fullName: "Super Rugby",
	// 	active: false,
	// 	isSoccer: false,
	// 	hasFutures: true,
	// 	fixtureType: "weekly",
	// 	valueSummaryEligible: true,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	hasLiveMatchPredictions: false,
	// 	usesOfficialLogos: true,
	// 	jerseyFileKey: "Abv",
	// 	showInScoreStrip: true,
	// 	navigablePages: [
	// 		{name: "Hub", path: "/sru", pageCode: "hub"},
	// 		{name: "Fixtures", path: "/bet-hub/sru/schedule", pageCode: "schedule"},
	// 		{name: "Futures", path: "/futures/sru", pageCode: "futures"},
	// 		{name: "Tipping", path: "/sru/tipping", pageCode: "tipping"},
	// 	]
	// },
	// {
	// 	code: "TRC",
	// 	shortName: "Rugby Championship",
	// 	fullName: "The Rugby Championship",
	// 	active: false,
	// 	isSoccer: false,
	// 	hasFutures: true,
	// 	fixtureType: "weekly",
	// 	valueSummaryEligible: true,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	hasLiveMatchPredictions: false,
	// 	usesOfficialLogos: true,
	// 	jerseyFileKey: "Abv",
	// 	showInScoreStrip: true,
	// 	navigablePages: [
	// 		{name: "Hub", path: "/trc", pageCode: "hub"},
	// 		{name: "Fixtures", path: "/bet-hub/trc/schedule", pageCode: "schedule"},
	// 		{name: "Futures", path: "/futures/trc", pageCode: "futures"},
	// 		{name: "Tipping", path: "/trc/tipping", pageCode: "tipping"},
	// 	]
	// },
	// {
	// 	code: "BBL",
	// 	shortName: "Big Bash",
	// 	fullName: "Big Bash League",
	// 	active: false,
	// 	isSoccer: false,
	// 	hasFutures: true,
	// 	fixtureType: "special",
	// 	valueSummaryEligible: true,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	hasLiveMatchPredictions: true,
	// 	usesOfficialLogos: true,
	// 	jerseyFileKey: "DisplayName",
	// 	showInScoreStrip: true,
	// 	navigablePages: [
	// 		{name: "Hub", path: "/bbl", pageCode: "hub"},
	// 		{name: "Fixtures", path: "/bet-hub/bbl/schedule", pageCode: "schedule"},
	// 		{name: "Futures", path: "/futures/bbl", pageCode: "futures"},
	// 		{name: "Player DNA", path: "/bbl/player-dna", pageCode: "player-dna"}
	// 	]
	// },
	// {
	// 	code: "RACING",
	// 	shortName: "Racing",
	// 	fullName: "Horse Racing",
	// 	active: true,
	// 	fixtureType: "special",
	// 	valueSummaryEligible: false,
	// 	propsValueSummaryEligible: false,
	//	liveBetsEligible: false,
	// 	hasLiveMatchPredictions: false,
	// 	usesOfficialLogos: true,
	// 	isSoccer: false,
	// 	hasFutures: false,
	// 	jerseyFileKey: "Abv",
	// 	showInScoreStrip: false,
	// 	navigablePages: [
	// 		{name: "Raceday Schedule", shortName: "Race Schedule", path: "/racing/schedule", queryParams: {day: "next"}, pageCode: "race-schedule"},
	// 		{name: "Quick View Form", path: "/racing/quickviewform", queryParams: {day: "next"}, pageCode: "quick-view-form"},
	// 		{name: "Cheat Sheet", path: "/racing/cheatsheet", queryParams: {day: "next"}, pageCode: "cheat-sheet"},
	// 	]
	// }

]