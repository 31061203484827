<div class="full-column">
    <div class="max-w-lg mx-auto flex flex-col gap-2">
        <img
            class="pro-logo"
            src="https://cdn.ciphersports.io/assets/dimers-pro-full-logo.svg"
            alt="Dimers Pro"
        >
        <h1>Transaction canceled</h1>
    
        <p>If you're having technical difficulties, or have any questions about Dimers Pro, please don't hesitate to get in touch.</p>
    
        <a class="proceed-button" routerLink="/contact-us">Contact Us</a>
        <a class="proceed-button" routerLink="/subscription">Back to Dimers Pro</a>
    </div>
</div>
