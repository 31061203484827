import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoogleTagManagerService } from './angular-google-tag-manager.service';
import { AuthHelperService } from './auth-helper.service';
import { BlogService } from './blog.service';

@Injectable({
  providedIn: 'root'
})
export class PromoRedirectGuard  {
  constructor(
    private blogService: BlogService,
    private authHelper: AuthHelperService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
  ) {}


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const bookmakerParam = route.paramMap.get("param1");
      const promoParam = route.paramMap.get("param2");
      const promoDataParam = route.paramMap.get("param3");

      if (promoDataParam) {
        if (bookmakerParam === "metabet") {
          if (promoParam) {
            return this.getOutboundLink(
              `https://dimers-redirect.go.metabet.io/bet/${promoParam}/${promoDataParam}`,
              false,
              route,
              promoDataParam
            )
          } else {
            return this.getOutboundLink(
              `https://dimers-redirect.go.metabet.io/bet/${promoDataParam}`,
              false,
              route,
              promoDataParam
            )
          }
        }


      } else if (promoParam) {
        if (bookmakerParam === "metabet") {
          return this.getOutboundLink(
            `https://dimers-redirect.go.metabet.io/bet/${promoParam}`,
            false,
            route
          )
        } else if (route.queryParamMap.has("src") && ["best-books", "review"].includes(route.queryParamMap.get("src"))) {
          this.blogService.getBookmakerData(bookmakerParam)
            .subscribe(bookData => {
              return this.getOutboundLink(bookData.cta_url, true, route);
            }, (err) => {
              // TODO
              console.error(err);
              return false;
            })
        } else {
          this.blogService.getBookmakerData(bookmakerParam)
            .subscribe(bookData => {
              this.blogService.getBookmakerPromo(promoParam)
              .subscribe(promoData => {
                return this.getOutboundLink(promoData.affiliate_link, true, route, bookData.name)
              }, (err) => {
                // TODO
                console.error(err);
                return false;
              })
            }, (err) => {
              // TODO
              console.error(err);
              return false;
            })
        }

      } else {
        const bookmakerID = /[^|]*/.exec(route.queryParamMap.get("promo_id"))[0]
        let res: Record<string, any> = null;
        this.blogService.getHyperlink(bookmakerParam)
          .pipe(
            finalize(() => {

                if (
                  res && route.queryParamMap.has("src")
                    && !["in_article", "bookies", "reviews"].includes(route.queryParamMap.get("src"))
                ) {
                  // construct c param
                  const redirectLink = `${this.authHelper.user?.id || ""}`
      
                  // construct affiliate link
                  let hyperLink = res.affiliate_hyperlink
                  hyperLink = hyperLink.includes('&c=')
                    ? hyperLink.replace(`&c=`, `&c=${redirectLink}`)
                    : `${hyperLink}?c=${redirectLink}`
      
                  return this.excludeBookies(res?.hyperlinked_url?.name || "")
                    ? this.redirectUrl(
                        `?src=in_article&affiliateLink=${encodeURIComponent(res.affiliate_hyperlink)}`,
                        res.hyperlinked_url.name,
                        state
                      )
                    : this.redirectUrl(
                        `?src=in_article&affiliateLink=${encodeURIComponent(hyperLink)}`,
                        res.hyperlinked_url.name,
                        state
                      )
                } else if (route.queryParamMap.get("src") === 'in_article') {
                  let url = route.queryParamMap.get("affiliateLink");
                  url = decodeURIComponent(decodeURIComponent(url))
      
                  // call gtag for in article link
                  if (res.hyperlinked_url?.name) {

                    const gtmTag = {
                      event: "exit",
                      event_category: "in_article",
                      event_label: res.hyperlinked_url.name,
                    };
              
                    return this.gtmService.pushTag(gtmTag)
                      .catch(e => {
                        throw new Error("Google Tag Manager Script failed: " + e)
                      })
                      .finally(() => {
                        return this.getOutboundLink(url, true, route);
                      });
                  } else {
                    return this.getOutboundLink(url, true, route)
                  }
                } else if (bookmakerID) {
                  this.blogService.getBookmakerData(bookmakerID)
                    .subscribe((bookData) => {
                      return this.getOutboundLink(
                        bookData.cta_url,
                        true,
                        route,
                        bookData.name
                      )
                    }, (err) => {
                      // TODO
                      console.error(err);
                      return false;
                    })
                } else {
                  this.blogService.getBookmakerPromo(promoParam)
                    .subscribe((promoData) => {
                      return this.getOutboundLink(
                        promoData.affiliate_link,
                        true,
                        route
                      )
                    }, (err) => {
                      // TODO
                      console.error(err);
                      return false;
                    })
                  
                }
              
            })
          )
          .subscribe((r) => {res = r;})
      }
  }

  excludeBookies(bookie: string): boolean {
    return ["bet365", "bet_365", "pointsbet", "betmgm"].includes(bookie.replace(/\s/g, "").toLowerCase());
  }

  redirectUrl(url: string, bookie: string, state: RouterStateSnapshot): boolean {
    // const self = this
    // gtag('event', 'exit', {
    //   event_category: 'in_article',
    //   event_label: bookie,
    //   event_callback(id) {
    //     if (id === self.gtmId) {
    //       self.$router.push(`${self.$router.currentRoute.fullPath}${url}`)
    //     }
    //   },
    // })
    this.router.navigateByUrl(`${state.url.split("?")[0]}${url}`)
    return true;
  }

  getOutboundLink(url: string, treatURL: boolean, route: ActivatedRouteSnapshot, bookmaker?: string): boolean {
    if (window) {

      if (treatURL && route) {
        if (url.includes("&c=")) {
          url = url.replace(
            /&c=.*?(&|$)/g,
            `&c=${route.queryParamMap.get("c")}`
          )
        } else if (route.queryParamMap.has("c")) {
          url = `${url}?c=${route.queryParamMap.get("c")}`;
        }
      }

      if (bookmaker) {
        // TODO send GTM tag for click event, and when done, redirect
        window.location.href = url;
        return true;
      } else {
        window.location.href = url;
        return true;
      }
    }
    
  }
  
}
