import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class AuthSignUpGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.auth.isAuthenticated$.pipe(
            tap(loggedIn => {
                if (!loggedIn) {
                    this.auth.loginWithRedirect({
                        appState: {
                            target: (window.location.pathname + window.location.search)
                          },
                        authorizationParams: {
                            redirect_uri: window.location.origin + "/callback",
                            screen_hint: "signup",
                            logged_in_at_path: window.location.pathname + window.location.search,
                        }
                    });
                }
            })
        );
    }
}
