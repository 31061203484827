import { Component, Input } from '@angular/core';
import { MatchTeam } from 'src/app/match-team.model';
import { TeamIconComponent } from '../team-icon/team-icon.component';

@Component({
    selector: 'app-team-icon-pair',
    templateUrl: './team-icon-pair.component.html',
    styleUrls: ['./team-icon-pair.component.scss'],
    standalone: true,
    imports: [TeamIconComponent]
})
export class TeamIconPairComponent {
  @Input() frontSportCode: string;
  @Input() frontTeam: MatchTeam;
  @Input() backSportCode: string;
  @Input() backTeam: MatchTeam;
}
