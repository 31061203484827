<div class="modal-overlay no-doc-scroll" *ngIf="visible"> 
    <div class="backdrop backdrop-blur-sm bg-black/50" (click)="setVisible()">
	</div>
	<div class="modal-dialog">
        <div class="close-button" (click)="setVisible()">
			<fa-icon [icon]="faTimes" aria-label="Close"></fa-icon>
		</div>
        <div class="text-base sm:text-lg font-bold">Legal Status</div>
        <div class="text-sm sm:text-base font-medium mt-2">Online sports betting isn't legal in {{state}}.</div>
        <div *ngIf="!this.offerLoading">
            <div *ngIf="offer">
                <div class="text-sm sm:text-base font-medium">
                    Check out this Daily Fantasy offer instead:
                </div>
                <div class="max-w-xs mx-auto mt-2 sm:mt-4">
                    <app-best-offer-card
                    [offer]="offer"
                    pageLocation="trends-page"
                    [stateCode]="state"
                ></app-best-offer-card>
                </div>
                <a [href]="oddsRedirectLink" class="mt-2 sm:mt-4 learn-more-btn block max-w-xs mx-auto text-center" target="_blank" (click)="setVisible()">
                    Continue to {{ bookmaker }}
                </a>
            </div>
            <div *ngIf="!offer">
                <div class="ttext-sm sm:text-base font-medium" *ngIf="!formSent" >
                    Subscribe to our newsletter to know when it becomes legal.
                </div>
                <div class="form-shell">
                    <div
                        class="form"
                        *ngIf="!formSent"
                        ref="form"
                    >
                    <div class="flex flex-col gap-1 mt-4">
                        <label htmlFor="email" class="text-sm font-semibold text-neutral-800">
                            Email:
                        </label>
                        <input
                            class="bg-neutral-100 p-2 rounded border border-transparent mt-1 text-neutral-800"
                            type="email"
                            [(ngModel)]="email"
                            placeholder="Email"
                            name="email"
                            ngDefaultControl
                            (keyup)="verifyForm()"
                        >
                    </div>
    
                    <div
                        class="submit-button mt-4 sm:mt-6"
                        [class.button-disabled]="!formValid || formSending"
                        (click)="sendEmail()"
                    >
                        {{ formSending ? 'Please wait...' : 'Sign Up' }}
                    </div>
                    </div>
                    <div class="font-base font-medium text-center"
                        *ngIf="formSent && !formError"
                    >
                        Thanks for joining. Stay tuned for more!
                    </div>
                    <div
                        *ngIf="formError"
                        class="font-base font-medium text-center"
                    >
                        Sorry, something went wrong sending your details. Please try again.
                    </div>
        
                </div>
            </div>
        </div>
        <div class="flex justify-center items-center min-h-[320px]" *ngIf="offerLoading">
            <div role="status">
                <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
        </div>
	</div>

</div>