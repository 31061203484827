import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatchTeam } from 'src/app/match-team.model';
import { Sport } from 'src/app/sport.model';
import { SPORTS } from 'src/app/sports';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-team-icon',
    templateUrl: './team-icon.component.html',
    styleUrls: ['./team-icon.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class TeamIconComponent implements OnChanges {
  @Input() sportCode: String;
  @Input() team: MatchTeam;
  sport: Sport;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes("sportCode")) {
      const sportCodeChange = changes["sportCode"];
    
      if (SPORTS.some(s => s.code === sportCodeChange.currentValue)) {
        this.sport = SPORTS.find(s => s.code === sportCodeChange.currentValue);
      } else {
        console.warn(`TeamIconComponent constructor failed - sport code ${sportCodeChange.currentValue} not found`);
      }
    }
  }

}
