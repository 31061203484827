import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { LocalisationService } from './localisation.service';
import { HOST_NAME } from "./host-name";
import { faRedoAlt } from '@fortawesome/pro-regular-svg-icons';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { NavigationStart } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { AuthHelperService } from './auth-helper.service';
import { DOCUMENT } from '@angular/common';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'Dimers';
	updatePromptVisible = false;
	
	newsletterPopUpVisible = false;
	showScore: boolean = true;
	showPartner: boolean = true;
	showMobileOffers: boolean = true;
	browserMode = false;
	// showDynamicTitle: boolean = false;

	constructor(
		private swUpdate: SwUpdate, 
		private localisationService: LocalisationService,
		private router: Router,
        @Inject(PLATFORM_ID) platformId: string,
		private authHelper: AuthHelperService,
        @Inject(DOCUMENT) private document: Document
	) {
        this.browserMode = isPlatformBrowser(platformId);
		this.localisationService.setLocale("US");

		this.router.events.forEach((event) => {
			if (event instanceof NavigationEnd) {
				if (event['url'] == '/responsible-gambling') {
					this.showScore = false;
					this.showPartner = false;
				} else {
					this.showScore = true;
					this.showPartner = true;
				}

				if(event['url'] == '/about-us' || event['url'] == '/responsible-gambling' || event['url'].includes('best-books') || event['url'] == '/privacy-policy' || event['url'] == '/terms-of-service' || event['url'] == '/sports-betting-101' || event['url'] == '/editorial-guidelines' ||  event['url'] == '/business' || event['url'] == '/subscription' || event['url'] == '/my-account' || event['url'] == '/subscription/promotion/betmgm' || event['url'] == '/email-verify-required' || event['url'] == '/subscription/promotion/pickpapa') {
					this.showMobileOffers = false;
				}
				else {
					this.showMobileOffers = true;
				}

				// //check if in the schedule page or match page
				// if(event['url'].includes("/schedule")) {
				// 	this.showDynamicTitle = true;
				// }
				// else {
				// 	this.showDynamicTitle = false;
				// }
			}
			// if (event instanceof NavigationStart && typeof window !== "undefined") {
			// 	(window as any)?.taboola?.push({notify:'newPageLoad'})
			// }
		});
	}

	ngOnInit() {
        //gettiing the scroll bar width, then used in pop up modal to avoid content shift
        if (typeof window !== "undefined") {
            const scrollBarWidth = window.innerWidth - this.document.documentElement.clientWidth;
            this.document.body.style.setProperty('--scrollbar-width', `${scrollBarWidth}px`);
        }

        if (this.swUpdate.isEnabled) {
			this.swUpdate.versionUpdates
				.pipe(
					filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
				)
				.subscribe(() => {
					// console.log("New Version Available");
					this.updatePromptVisible = true;
				});
		}

		// if/when user is logged in, inform ProfitWell of user's email address
		if (this.browserMode) {
			this.authHelper.detailedUser$.subscribe((user) => {
				if (user?.email) {
					(window as any)?.profitwell?.("user_email", user.email)
				}
			})
		}
	}

	reloadPage() {
		if (typeof window !== "undefined") {
			window.location.reload();
		}
	}

	fireNewsletterPopUp = (): void => {
		this.newsletterPopUpVisible = true;
	}

	hideNewsletterPopUp = (): void => {
		this.newsletterPopUpVisible = false;
	}
	
	faRedoAlt = faRedoAlt;
}
