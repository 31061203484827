// import { DISQUS_SHORTNAME } from "ngx-disqus";

import { BrowserModule, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { NgModule, ErrorHandler, importProvidersFrom } from '@angular/core';
import { HttpClientModule, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
// import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';




// import { RollbarService, rollbarFactory, RollbarErrorHandler } from "./rollbar";
import { GlobalErrorHandler } from './global-error-handler'
import { GoogleTagManagerModule } from './angular-google-tag-manager.module';

import { CookieModule } from "ngx-cookie";

import { NthPipe } from './nth/nth.pipe';

import { IsLivePipe } from './match-pipes/is-live.pipe';
import { IsPreMatchOnlyPipe } from './match-pipes/is-pre-match-only.pipe';
import { IsOverPipe } from './match-pipes/is-over.pipe';
import { IsPausedPipe } from './match-pipes/is-paused.pipe';
import { BookBackgroundColourPipe } from './book-pipes/book-background-colour.pipe';
import { BookForegroundColourPipe } from './book-pipes/book-foreground-colour.pipe';
import { BookIconAltPipe } from './book-pipes/book-icon-alt.pipe';
import { BookIconPipe } from './book-pipes/book-icon.pipe';
import { BookLogoAltPipe } from './book-pipes/book-logo-alt.pipe';
import { BookLogoPipe } from './book-pipes/book-logo.pipe';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { HighlightModule, HIGHLIGHT_OPTIONS, HighlightOptions } from 'ngx-highlightjs';
import { IMAGE_LOADER, ImageLoaderConfig, TitleCasePipe } from '@angular/common';
import { register } from "swiper/element/bundle";
import { SiteHeaderComponent } from './general/site-header/site-header.component';
import { SiteFooterComponent } from './general/site-footer/site-footer.component';
import { SubscribeClickPromptComponent } from './general/subscribe-click-prompt/subscribe-click-prompt.component';
import { NonLegalModalComponent } from './general/non-legal-modal/non-legal-modal.component';
import { ProModalComponent } from './general/pro-modal/pro-modal.component';
import { FeedbackFormComponent } from './general/feedback-form/feedback-form.component';
import { MatNativeDateModule } from '@angular/material/core';

register();

// declare global {
// 	interface Window { analytics: any; }
// }

@NgModule({
	declarations: [
		AppComponent,
	],
	providers: [
		// { provide: DISQUS_SHORTNAME, useValue: 'dimers'},
		{ provide: ErrorHandler, useClass: GlobalErrorHandler},
		// { provide: RollbarService, useFactory: rollbarFactory },
		NthPipe,
		IsLivePipe,
		IsPreMatchOnlyPipe,
		IsOverPipe,
		IsPausedPipe,
		BookBackgroundColourPipe,
		BookForegroundColourPipe,
		BookIconAltPipe,
		BookIconPipe,
		BookLogoAltPipe,
		BookLogoPipe,
		provideClientHydration(
			withHttpTransferCacheOptions({includePostRequests: true}),
		),
		TitleCasePipe,
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
              coreLibraryLoader: () => import('highlight.js/lib/core'),
              languages: {
                typescript: () => import('highlight.js/lib/languages/typescript'),
                css: () => import('highlight.js/lib/languages/css'),
                xml: () => import('highlight.js/lib/languages/xml')
              }
            }
        },
		{
			provide: IMAGE_LOADER,
			useValue: (config: ImageLoaderConfig) => {
				if (config.loaderParams.imgix) {
					// modified form of official Angular source code for default Imgix loader
					// (https://github.com/angular/angular/blob/main/packages/common/src/directives/ng_optimized_image/image_loaders/imgix_loader.ts)
					const url = new URL(`${config.src}`);
					// This setting ensures the smallest allowable format is set.
					url.searchParams.set('auto', 'format');
					if (config.width) {
						url.searchParams.set('w', config.width.toString());
					}

					// When requesting a placeholder image we ask a low quality image to reduce the load time.
					if (config.isPlaceholder) {
						url.searchParams.set('q', "20");
					}
					return url.href;
				}
			},
		  },
		  provideHttpClient(withInterceptorsFromDi(), withFetch()),
		importProvidersFrom(MatNativeDateModule),
	],
	imports: [
		HighlightModule,
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		AppRoutingModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		FontAwesomeModule,
		BreadcrumbComponent,
		BreadcrumbItemDirective,
		// NgxGoogleAnalyticsModule.forRoot(environment.gaKey),
		// NgxGoogleAnalyticsRouterModule,
		GoogleTagManagerModule.forRoot({
			id: environment.gtmKey,
		}),
		CookieModule.forRoot(),
		SiteHeaderComponent,
		SiteFooterComponent,
		SubscribeClickPromptComponent,
		NonLegalModalComponent,
		ProModalComponent,
		FeedbackFormComponent,
],
	bootstrap: [AppComponent]
})
export class AppModule { }
