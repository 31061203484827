import { Component, Input } from '@angular/core';
import { FlagIconComponent } from '../flag-icon/flag-icon.component';

@Component({
    selector: 'app-flag-icon-pair',
    templateUrl: './flag-icon-pair.component.html',
    styleUrls: ['./flag-icon-pair.component.scss'],
    standalone: true,
    imports: [FlagIconComponent]
})
export class FlagIconPairComponent {
  @Input() backCountryCode: string;
  @Input() frontCountryCode: string;
}
