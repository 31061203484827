import { Injectable } from "@angular/core";
import { GoogleTagManagerService } from "./angular-google-tag-manager.service";
import { GeneralService } from "./general.service";
import { MatchBet } from "./match-bet.model";
import { Match } from "./match.model";
import { BehaviorSubject, map, Observable } from "rxjs";
import { AuthHelperService } from "./auth-helper.service";

@Injectable({
	providedIn: "root"
})
export class BetService {

	customBookmakerLinks =  {
		"draftkings": "https://dksb.sng.link/As9kz/kwto?_dl=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d756127514%26wpcid%3d324655%26wpsrc%3d2115%26wpcn%3dGen%26wpscn%3dPromo1%26wpcrn%3dDimersWelcome%26wpscid%3dxx%26wpcrid%3dxx&_fallback_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d756127514%26wpcid%3d324655%26wpsrc%3d2115%26wpcn%3dGen%26wpscn%3dPromo1%26wpcrn%3dDimersWelcome%26wpscid%3dxx%26wpcrid%3dxx&pcid=324655&psn=2115&pcn=Gen&pscn=Promo1&pcrn=DimersWelcome&pscid=xx&pcrid=xx&_ios_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d756127514%26wpcid%3d324655%26wpsrc%3d2115%26wpcn%3dGen%26wpscn%3dPromo1%26wpcrn%3dDimersWelcome%26wpscid%3dxx%26wpcrid%3dxx&_android_redirect=https%3a%2f%2fsportsbook.draftkings.com%2fgateway%3fs%3d756127514%26wpcid%3d324655%26wpsrc%3d2115%26wpcn%3dGen%26wpscn%3dPromo1%26wpcrn%3dDimersWelcome%26wpscid%3dxx%26wpcrid%3dxx",
		"william_hill": "https://wlwilliamhillus.adsrv.eacdn.com/C.ashx?btag=a_18280b_2c_&affid=81&siteid=18280&adid=2&c=",
		"betmgm": "https://mediaserver.partners.roardigital.com/renderBanner.do?zoneId=1615193",
		"pointsbet": "https://record.pointsbetpartners.com/_3zudlUxeY0ue0txtjhppomNd7ZgqdRLk/5/",
		"fanduel": "https://wlfanduelus.adsrv.eacdn.com/C.ashx?btag=a_39121b_14c_&affid=12910&siteid=39121&adid=14&c=",
		"bet_rivers_in": "https://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_1885b_600c_&affid=620&siteid=1885&adid=600&c=",
		"bet_rivers_va": "https://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_1885b_1306c_&affid=620&siteid=1885&adid=1306&c=",
		"bet_rivers_co": "http://wlsugarhouseaffiliates.adsrv.eacdn.com/C.ashx?btag=a_1885b_750c_&affid=620&siteid=1885&adid=750&c=",
		"betway": "https://us.betway.com/sports?s=bwa25046&a=bwaadid141584",
		"tipico": "https://wltipico.adsrv.eacdn.com/C.ashx?btag=a_23b_43c_&affid=23&siteid=23&adid=43&c=",
		"bet365": "https://www.bet365.com/olp/open-account?affiliate=365_02310779",
		"bet_365": "https://www.bet365.com/olp/open-account?affiliate=365_02310779",
		"unibet": "https://www.csplen.com/C.ashx?btag=a_734b_584c_&affid=179&siteid=734&adid=584&c=",
		"twinspires": "http://wlbetamerica.adsrv.eacdn.com/C.ashx?btag=a_2559b_2321c_&affid=1997&siteid=2559&adid=2321&c=",
		"wynn": "https://go.dime.rs/WynnBet-Generic-Widget",
	};

	bookmakerAffiliateCodes: Record<string, string> = {};
	
    private sharedModalDataSubject = new BehaviorSubject<boolean>(false);
    sharedModalData$ = this.sharedModalDataSubject.asObservable();

    private sharedBookMakerDataSubject = new BehaviorSubject<string>("");
    sharedBookMakerData$ = this.sharedBookMakerDataSubject.asObservable();

    private sharedOddsRedirectLinkDataSubject = new BehaviorSubject<string>("");
    sharedOddsRedirectLinkData$ = this.sharedOddsRedirectLinkDataSubject.asObservable();

    private sharedLegalOnlineBettingDataSubject = new BehaviorSubject<boolean>(true);
    sharedLegalOnlineBettingData$ = this.sharedLegalOnlineBettingDataSubject.asObservable();

	constructor(
		public generalService: GeneralService,
		public gtmService: GoogleTagManagerService,
		private authHelper: AuthHelperService,
	) { }

	populateTileBettingInfo(match: Match): void {
		if (match.MatchData) {



			if (match.MatchData.Sport.toLowerCase() === "mlb" || match.MatchData.Sport.toLowerCase() === "nhl"
				|| this.generalService.isSoccer(match.MatchData.Sport)) {
				if (!match.aggregatedBettingInfo || !match.aggregatedBettingInfo.HomeOdds || !match.aggregatedBettingInfo.AwayOdds) {
					match.bettingInfoUnavailable = true;
				}
			} else if (match.MatchData.Sport.toLowerCase() === "ten") {
				if (!match.aggregatedBettingInfo || !match.aggregatedBettingInfo.H2HOdds || !match.aggregatedBettingInfo.H2HOdds.player1
					|| !match.aggregatedBettingInfo.H2HOdds.player2) {
					match.bettingInfoUnavailable = true;
				}
			} else {
				if (!match.aggregatedBettingInfo || !match.aggregatedBettingInfo.HomeLine || !match.aggregatedBettingInfo.HomeLineWinPct
					|| !match.aggregatedBettingInfo.AwayLineWinPct) {
					match.bettingInfoUnavailable = true;
				}
			}
		}
	}

	bestBetName(bet: MatchBet, sport: string): string {
		if (bet.type === "h2h") {
			return "Moneyline";
		}
		if (bet.type === "firstset") {
			return "First Set";
		}
		if (bet.type === "total") {
			return "Over/Under";
		}
		if (bet.type === "bts") {
			return "Both Teams to Score";
		}
		if (bet.type === "line") {
			if (sport.toLowerCase() === "mlb") {
				return "Run Line";
			}
			if (sport.toLowerCase() === "nhl") {
				return "Puck Line";
			}

			return "Spread";
		}

		return "";
	}

	valueFireOpacity(edge: number): number {
		// if (edge < 0) {
		// 	return 0;
		// }

		// if (edge >= 10) {
		// 	return 1;
		// }

		// return 0.2 + edge * 0.08;

		if (edge < 4) {
			return 0;
		}

		return 1;
	}

	bookmakerLink(bookieCode: string, deepLinkOptions?: Record<string, any>): string {
		if (deepLinkOptions) {
			if ((bookieCode === "bet365" || bookieCode === "bet_365")
				&& Object.keys(this.bookmakerAffiliateCodes).includes(bookieCode)) {
				return `http://www.bet365.com/betslip/instantbet/default.aspx?fid=${deepLinkOptions.FID}`
					+ `&participantid=${deepLinkOptions.ID}`
					+ `&affiliatecode=${this.bookmakerAffiliateCodes[bookieCode]}`
					+ `&odds=${deepLinkOptions.Odds}&Instantbet=1&AVG=${deepLinkOptions.AVG}`;
			}

			if (["betmgm", "bet_rivers_in", "sugarhouse"].includes(bookieCode) && deepLinkOptions.short_link) {
				return deepLinkOptions.short_link;
			}

			// TODO support for deep links from other bookmakers
		}
		if (Object.keys(this.customBookmakerLinks).includes(bookieCode)) {
			return this.customBookmakerLinks[bookieCode];
		}

		// TODO should we return a generic link that matches the bookie code and just goes to their home page?
		return "";
	}

	stylisedBookName(bookieCode: string): string {
		if (bookieCode === "fanduel") {return "FanDuel"; }
		if (bookieCode === "sugarhouse") {return "SugarHouse"; }
		if (bookieCode === "pointsbet") {return "PointsBet"; }
		if (bookieCode === "william_hill") {return "Caesars"; }
		if (bookieCode === "draftkings") {return "DraftKings"; }
		if (bookieCode === "wynn") {return "WynnBET"; }
		if (bookieCode === "betway") {return "Betway"; }
		if (bookieCode === "tipico") {return "Tipico"; }
		if (bookieCode === "unibet") {return "Unibet"; }
		if (bookieCode === "bet365" || bookieCode === "bet_365") {return "bet365"; }
		if (bookieCode === "betmgm") {return "BetMGM"; }
		if (bookieCode === "twinspires") {return "TwinSpires"; }
		if (["bet_rivers_in", "bet_rivers_va", "bet_rivers_co"].includes(bookieCode)) {return "BetRivers"; }

		// TODO more bookies
		return "";
	}

	formatOdds(decimalOdds: number): Observable<string> {
		return this.authHelper.detailedUser$.pipe(
			map((user => {
			if (user?.user_metadata?.odds_preference === "decimal") {
				return decimalOdds.toFixed(2);
			} else if (user?.user_metadata?.odds_preference === "american") {
				return this.formatOddsAmerican(decimalOdds);
			} else {
				return this.formatOddsDefault(decimalOdds);
			}
		})))
		
	}

	formatOddsDefault(decimalOdds: number): string {
		return this.formatOddsAmerican(decimalOdds);
	}

	private formatOddsAmerican(decimalOdds: number): string {
		if (decimalOdds < 2) {
			const minusNumber = 100 / (decimalOdds - 1);
			return "-" + minusNumber.toFixed(0);
		}

		const plusNumber = (decimalOdds * 100) - 100;
		return "+" + plusNumber.toFixed(0);
	}

	goToPromoLink(url: string, bookCode: string, sourceBlock: string): void {
		const gtmTag = {
			event: "exit",
			event_category: sourceBlock,
			event_label: bookCode,
		};
	
		this.gtmService.pushTag(gtmTag)
			.then(() => {
				window.open(url, "_self");
			})
			.catch(e => {
				console.error("Google Tag Manager Script failed: " + e)
				window.open(url, "_self");
			});
	}

    setSharedModalData(data: boolean) {
        this.sharedModalDataSubject.next(data);
    }

    setSharedBookMakerData(data: string) {
        this.sharedBookMakerDataSubject.next(data);
    }

    setSharedOddsRedirectLinkData(data: string) {
        this.sharedOddsRedirectLinkDataSubject.next(data);
    }

    setSharedLegalOnlineBettingData(data: boolean) {
        this.sharedLegalOnlineBettingDataSubject.next(data);
    }
}
