import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaSetterService } from '../meta-setter.service';

@Component({
    selector: 'app-search-results-page',
    templateUrl: './search-results-page.component.html',
    styleUrls: ['./search-results-page.component.scss'],
    standalone: true
})
export class SearchResultsPageComponent implements OnInit {
	searchScriptLoaded = false;
	query = "";
	constructor(private route: ActivatedRoute, private metaSetter: MetaSetterService) { }

	ngOnInit(): void {
		this.metaSetter.setMeta({
			title: "Search Results",
			seoTitle: "Search Results",
			description: "No more watching from the sidelines. Dimers gives sports fans the news, tools & analysis to Make the Play.",
			// metaImageURL: "https://cdn.ciphersports.io/images/dimers-site/generic_meta.jpeg",
            canonicalURL: "https://www.dimers.com/search",
		})
		this.initialiseSearchResultsBlock();
		this.query = this.route.snapshot.queryParamMap.get("q");
		this.route.queryParams.subscribe((params) => {
			if (params.q && params.q !== this.query) {
				this.query = "";

				this.initialiseSearchResultsBlock();
			}
		})
	}

	initialiseSearchResultsBlock(): void {

		if (typeof document !== "undefined") {
			// hack to clear out old search results block
			const blockContainer = document.getElementById("search-results-ctr");
			const newBlockContainer = document.createElement("div");
			newBlockContainer.className = "gcse-searchresults-only";
			newBlockContainer.id = "search-results-ctr";
			blockContainer.parentNode.replaceChild(newBlockContainer, blockContainer);

			var s: any = document.createElement("script");
			s.src = "https://cse.google.com/cse.js?cx=003493486438172541894:aruu13yb1vg";
			s.async = true;
			s.onload = () => this.searchScriptLoaded = true;
			if (!Array.from(document.scripts).some(scr => scr.src === s.src)) {
				document.head.appendChild(s);
			} else {
				let toBeDeleted = Array.from(document.scripts).find(scr => scr.src === s.src);
				document.head.removeChild(toBeDeleted);
				document.head.appendChild(s);
			}
		}

		
	}

}
