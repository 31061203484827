<section class="px-[10px] pb-12 max-w-[1270px] mx-auto">
    <div>
        <div class="text-2xl text-gray-900 text-center font-semibold items-center">
            As seen on
        </div>
        <div class="flex gap-6 items-center justify-center flex-wrap mt-8">
            <img  loading="lazy" class="max-sm:h-10 max-sm:w-[108px]" src="https://imgix.cosmicjs.com/a4b79ac0-90f8-11ef-96d9-611c8679cda8-1.svg?w=200&auto=format,compress" alt="Fox Sports icon" height="48" width="130">
            <img  loading="lazy" class="max-sm:h-10 max-sm:w-[108px]" src="https://imgix.cosmicjs.com/a4b48d80-90f8-11ef-96d9-611c8679cda8-2.svg?w=200&auto=format,compress" alt="ESPN icon" height="48" width="130">
            <img  loading="lazy" class="max-sm:h-10 max-sm:w-[108px]" src="https://imgix.cosmicjs.com/a4b99690-90f8-11ef-96d9-611c8679cda8-3.svg?w=200&auto=format,compress" alt="USA Today icon" height="48" width="130">
            <img  loading="lazy" class="max-sm:h-10 max-sm:w-[108px]" src="https://imgix.cosmicjs.com/a4b88520-90f8-11ef-96d9-611c8679cda8-4.svg?w=200&auto=format,compress" alt="Forbes icon"  height="48" width="130">
            <img  loading="lazy" class="max-sm:h-10 max-sm:w-[108px]" src="https://imgix.cosmicjs.com/a4b66240-90f8-11ef-96d9-611c8679cda8-5.svg?w=200&auto=format,compress" alt="MLS icon" height="48" width="130">
            <img  loading="lazy" class="max-sm:h-10 max-sm:w-[108px]" src="https://imgix.cosmicjs.com/a498ef30-90f8-11ef-96d9-611c8679cda8-6.svg?w=200&auto=format,compress" alt="Bleacher Report icon" height="48" width="130">
            <img  loading="lazy" class="max-sm:h-10 max-sm:w-[108px]" src="https://imgix.cosmicjs.com/a4a10580-90f8-11ef-96d9-611c8679cda8-7.svg?w=200&auto=format,compress" alt="Sports Illustrated icon" height="48" width="130">
            <img  loading="lazy" class="max-sm:h-10 max-sm:w-[108px]" src="https://imgix.cosmicjs.com/a49ff410-90f8-11ef-96d9-611c8679cda8-8.svg?w=200&auto=format,compress" alt="Yahoo icon" height="48" width="130">
        </div>
    </div>
</section>