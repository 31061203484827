<div class="flex flex-col md:flex-row gap-10 md:items-center pt-0 pb-10 md:py-10 max-md:px-5">
    <div class="flex flex-col gap-5 max-md:order-last">
        <div class="text-2xl md:text-5xl sm:text-3xl font-bold">
            DimersBOT may have fumbled!
        </div>
        <div class="text-base font-medium md:text-xl">
            Sorry, we couldn’t find the page you were looking for.
        </div>
        <div class="md:mt-6 flex gap-5 sm:block">
            <button class="go-back-btn h-10 max-sm:w-full" (click)="goBackButtonClick()">
                Go back
            </button>
            <button class="go-home-btn h-10 max-sm:w-full" routerLink="/">
                Go to home
            </button>
        </div>
    </div>
    <div class="">
        <img src="https://imgix.cosmicjs.com/535c8040-6279-11ee-bacf-0595d1e10c0d-error404-bot.png?auto=format,compress&w=684" alt="" width="684">
    </div>
</div>
<section class="flex flex-col gap-4 md:gap-8 max-w-3xl mx-auto mb-10">
    <div class="text-xl md:text-2xl font-bold text-center">
        Sports Betting Tools You Might Like
    </div>
    <div class="carousel-ctr relative">
        <div class="carousel-prev-button w-10 absolute left-0 h-10 cursor-pointer z-10 flex justify-center items-center">
            <fa-icon [icon]="faChevronLeft" class="carousel-nav-icon"></fa-icon>
        </div>
        <swiper-container init="false" class="mySwiper px-6 md:p-0" #swiper>
            <swiper-slide *ngFor="let slider of sliders">
                <div class="flex items-center justify-center">
                    <img [src]="slider.img" alt="img" class="rounded-2xl max-h-60">
                </div>
                <div class="flex flex-col gap-4 mt-4 items-center">
                    <div class="text-lg md:text-xl font-bold">
                        {{ slider.title }}
                    </div>
                    <div class="text-base font-medium max-sm:text-justify text-center">
                        {{slider.description }}
                    </div>
                    <a [href]="slider.link" [routerLink]="slider.link" class="redirect-btn sm:max-w-fit">
                        Go To {{ slider.title }}
                    </a>
                </div>
            </swiper-slide>
        </swiper-container>
        <div class="carousel-next-button w-10 absolute right-0 h-10 cursor-pointer z-10 flex justify-center items-center">
            <fa-icon [icon]="faChevronRight"></fa-icon>
        </div>
    </div>
</section>
