<!-- Dropdown -->
<div class="nav-item dropdown"
    [class.first-item]="firstItem"
    [class.user-dropdown]="navItem.type === 'user-dropdown'"
    *ngIf="navItem.type === 'dropdown' || navItem.type === 'more-dropdown' || navItem.type === 'user-dropdown'"
    (mouseover)="showDropdown()"
    (mouseout)="hideDropdown()"
>

    <!-- Dropdown with Link - Internal Link -->
    <a *ngIf="navItem.href && !navItem.strictHref && navItem.type !== 'user-dropdown'"
        class="dropdown-head-link"
        [routerLink]="navItem.href"
        [queryParams]="navItem.queryParams"
        (click)="navItem.onClick?.($event)"
    >
        {{ navItem.title }}
        <fa-icon class="down-arrow" [icon]="faChevronDown"></fa-icon>
    </a>

    <!-- Dropdown with Link - External Link -->
    <a *ngIf="navItem.href && navItem.strictHref && navItem.type !== 'user-dropdown'"
        class="dropdown-head-link"
        [href]="navItem.href"
        [target]="navItem.newTab ? '_blank' : '_self'"
        (click)="navItem.onClick?.($event)"
    >
        {{ navItem.title }}
        <fa-icon class="down-arrow" [icon]="faChevronDown"></fa-icon>
    </a>

    <!-- Dropdown with No Link -->
    <span *ngIf="!navItem.href && navItem.type !== 'user-dropdown'">
        <span *ngIf="navItem.type === 'more-dropdown'"
            (click)="navItem.onClick?.($event)">
            <fa-icon class="more-icon" [icon]="faEllipsisH" aria-label="More" role="button"></fa-icon>
        </span>

        <span *ngIf="navItem.type !== 'more-dropdown'"
            (click)="navItem.onClick?.($event)">
            {{ navItem.title }}
            <fa-icon class="down-arrow" [icon]="faChevronDown"></fa-icon>
        </span>
    </span>

    <!-- User Dropdown -->
    <ng-container *ngIf="navItem.type === 'user-dropdown'">
        <div (click)="navItem.onClick?.($event)" class="flex flex-row justify-center items-center">
            <div class="user-icon-ctr">
                <img *ngIf="(proUser$ | async) === true" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6 mr-2" alt="dimers pro icon">
                <img *ngIf="(proUser$ | async) === false"  src="https://imgix.cosmicjs.com/e87d0d90-df63-11ee-a698-6f33e3e4a628-Dimers-Round-Logo-1.svg" class="h-6 w-6 mr-2" alt="dimers basic icon">
            </div>
            <fa-icon class="down-arrow" [icon]="faChevronDown"></fa-icon>
        </div>
        
    </ng-container>
    

    
    <div class="dropdown-pip" [hidden]="!dropdownOpen"></div>
    
    <div class="dropdown-shell"
        [class.centred]="dropdownCentred"
        [hidden]="!dropdownOpen"
        (click)="nested ? $event.stopPropagation() : null">
        <div class="dropdown-menu" [class.my-account]="navItem.type === 'user-dropdown'">
            <div class="sport-dropdown-ctr" *ngIf="nested">
                <!-- two-level navigation -->
                <!-- Top level links -->
                <div class="betting-list">
                    <ng-container *ngFor="let subLink of navItem.subLinks; index as index">
                        <!-- Non-Link -->
                        <div class="betting-item" *ngIf="!subLink.href" (mouseover)="setActiveTopLevel(index)" [class.highlighted]="activeTopLevel === index"
                            (click)="subLink.onClick?.($event)">
                            {{ subLink.title }}
                            <fa-icon class="right-arrow" [hidden]="activeTopLevel !== index || !subLink.subLinks" [icon]="faChevronRight"></fa-icon>
                        </div>

                        <!-- Internal Link -->
                        <a *ngIf="subLink.href && !subLink.strictHref" class="betting-item" (mouseover)="setActiveTopLevel(index)" [class.highlighted]="activeTopLevel === index"
                            (click)="subLink.onClick?.($event)"
                            [routerLink]="subLink.href"
                            [queryParams]="subLink.queryParams">
                            {{ subLink.title }}
                            <fa-icon class="right-arrow" [hidden]="activeTopLevel !== index || !subLink.subLinks" [icon]="faChevronRight"></fa-icon>
                        </a>

                        <!-- External Link -->
                        <a *ngIf="subLink.href && subLink.strictHref" class="betting-item" (mouseover)="setActiveTopLevel(index)" [class.highlighted]="activeTopLevel === index"
                            (click)="subLink.onClick?.($event)"
                            [href]="subLink.href"
                            [target]="subLink.newTab ? '_blank' : '_self'">
                            {{ subLink.title }}
                            <fa-icon class="right-arrow" [hidden]="activeTopLevel !== index || !subLink.subLinks" [icon]="faChevronRight"></fa-icon>
                        </a>
                    </ng-container>

                </div>

                <!-- Secondary level links -->
                <div class="secondary-panel betting-secondary-panel"
                    [class.condensed]="condensedSecondLevel">
                    <ng-container *ngFor="let parentSubLink of navItem.subLinks; index as parentIndex">
                        <div class="betting-links" *ngIf="parentSubLink.subLinks"
                            [hidden]="activeTopLevel !== parentIndex"
                        >
                            <ng-container *ngFor="let childSubLink of parentSubLink.subLinks">
                            
                                <!-- Non-Link -->
                                <div class="secondary-cta"
                                    *ngIf="!childSubLink.href"
                                    [class.condensed]="condensedSecondLevel"
                                    (click)="childSubLink.onClick?.($event)">
                                    <div class="flex gap-2 items-center justify-center">
                                        <img *ngIf="childSubLink.iconPath" loading="lazy" [src]="childSubLink.iconPath"
                                            height="20" width="20" alt="">
                                        {{ childSubLink.title }}
                                    </div>
                                </div>

                                <!-- Internal Link -->
                                <a class="secondary-cta"
                                    *ngIf="childSubLink.href && !childSubLink.strictHref"
                                    [class.condensed]="condensedSecondLevel"
                                    (click)="childSubLink.onClick?.($event)"
                                    [routerLink]="childSubLink.href"
                                    [queryParams]="childSubLink.queryParams"
                                >
                                    <div class="flex gap-2 items-center justify-center">
                                        <img *ngIf="childSubLink.iconPath" loading="lazy" [src]="childSubLink.iconPath"
                                            height="20" width="20" alt="">
                                        {{ childSubLink.title }}
                                    </div>
                                </a>
                                <!-- External Link -->
                                <a class="secondary-cta"
                                    *ngIf="childSubLink.href && childSubLink.strictHref"
                                    [class.condensed]="condensedSecondLevel"
                                    (click)="childSubLink.onClick?.($event)"
                                    [href]="childSubLink.href"
                                    [target]="childSubLink.newTab ? '_blank' : '_self'"
                                >
                                    <div class="flex gap-2 items-center justify-center">
                                        <img *ngIf="childSubLink.iconPath" loading="lazy" [src]="childSubLink.iconPath"
                                            height="20" width="20" alt="">
                                        {{ childSubLink.title }}
                                    </div>
                                </a>
                            </ng-container>
                        </div>
                    </ng-container>

                </div>
            </div>

            <div class="more-link-list" *ngIf="!nested"
                [class]="navItem.type === 'user-dropdown' ? 'divide-y divide-neutral-300' : ''">
                <ng-container *ngFor="let subLink of navItem.subLinks">
                    <a class="more-link flex items-stretch" *ngIf="subLink.strictHref"
                        [href]="subLink.href"
                        [target]="subLink.newTab ? '_blank' : '_self'"
                        (click)="subLink.onClick?.($event)"
                    >
                        <div class="flex gap-2 items-center">
                            <img *ngIf="subLink.iconPath" [src]="subLink.iconPath" width="25" height="25" alt="icon" loading="lazy">
                            {{ subLink.title }}
                        </div>
                        <span *ngIf="subLink.subtitle" class="text-xs min-w-max font-medium">{{ subLink.subtitle }}</span>
                    </a>
                    <a class="more-link flex items-stretch" *ngIf="!subLink.strictHref"
                        [routerLink]="subLink.href"
                        [queryParams]="subLink.queryParams"
                        (click)="subLink.onClick?.($event)"
                    >
                        <div class="flex gap-2 items-center">
                            <img *ngIf="subLink.iconPath" [src]="subLink.iconPath" width="25" height="25" alt="icon" loading="lazy">
                            {{ subLink.title }}
                        </div>
                        <span *ngIf="subLink.subtitle" class="text-xs min-w-max font-medium">{{ subLink.subtitle }}</span>
                    </a>
                </ng-container>
                
            </div>
        </div>
    </div>
</div>

<!-- Link with No Dropdown - External Link -->
<a class="nav-item link" *ngIf="navItem.type === 'link' && navItem.strictHref"
    [class.first-item]="firstItem"
    [href]="navItem.href"
    [target]="navItem.newTab ? '_blank' : '_self'"
>
    {{ navItem.title }}
</a>


<!-- Link with No Dropdown - Internal Link -->
<a class="nav-item link" *ngIf="navItem.type === 'link' && !navItem.strictHref"
    [class.first-item]="firstItem"
    [routerLink]="navItem.href"
    [queryParams]="navItem.queryParams"
>
    {{ navItem.title }}
</a>