import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlogService } from './blog.service';

@Injectable({
  providedIn: 'root'
})
export class LandingPageGuard  {
  constructor(
    private blogService: BlogService,
    private router: Router,
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.blogService.landingPageExists(route.paramMap.get("pagecode"))
      .pipe(
        map(result => {
          if (result) {
            return true;
          } else {
            this.router.navigate(["/notfound"], {skipLocationChange: true});
            return false;
          }
        })
      )
  }
  
}
