import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { faCheckCircle, faCopy, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BlogService } from 'src/app/blog.service';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { RouterLink, NavigationEnd, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor, TitleCasePipe, isPlatformBrowser, AsyncPipe } from '@angular/common';
import { filter, map, Observable, shareReplay } from 'rxjs';

@Component({
    selector: 'app-pro-modal',
    templateUrl: './pro-modal.component.html',
    styleUrls: ['./pro-modal.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FontAwesomeModule,
        NgFor,
        RouterLink,
        TitleCasePipe,
        AsyncPipe,
    ]
})
export class ProModalComponent implements OnInit, AfterViewInit {

    betIntent: boolean = false;
    visible = false;
    faTimes = faTimes;
    frequencyOptions = ["weekly", "monthly", "yearly"];
    selectedFrequency = "monthly";
    faCheckCircle = faCheckCircle;
    faCopy = faCopy;
    faCheck = faCheck;

    processing: boolean = false;
    startCopy:boolean = false;
    browserMode: boolean = false;
    
    proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
        map((detailedUser) => {
            return !!detailedUser?.app_metadata?.dimers_pro
        }),
        shareReplay(1)
    );

    // allowedUrls: string[] = ['/best-bets', '/prop-bets', '/parlay-picker', '/bet-hub/', '/futures/'];
    allowedUrls: string[] = ['subscription/sport/nba', 'subscription/sport/cbb'];
    constructor(
        private blogService: BlogService,
        public authHelper: AuthHelperService,
        private gtmService: GoogleTagManagerService,
        @Inject(PLATFORM_ID) platformId: string,
        private router: Router
    ) { 
        this.browserMode = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        this.blogService.proModalData$.subscribe(data => {
            this.visible = data.modalVisible;
            this.betIntent = data.betIntent;
            this.processing = !data;
        });
    }

    ngAfterViewInit(): void {
        if (this.blogService.proPopUp && this.browserMode) {
            this.authHelper.detailedUser$.subscribe(res=>{
                if(res?.app_metadata?.dimers_pro === false && res?.logins_count !== 1){
                    setTimeout(() => {
                        this.blogService.setProModalData(true);
                        this.blogService.disablePopUpOffer();
                    }, 5000);
                }
            });
        }

    }

    setVisible() {
        this.blogService.setProModalData(false);
    }

    redirecting() {
        this.processing = true;
    }

    setFrequency(freq: string) {
        this.selectedFrequency = freq;
        const gtmTag = {
            event: "pro_plan_toggle",
            event_category: "modal",
            event_label: freq,
        };

        this.gtmService.pushTag(gtmTag)
            .catch(e => {
                console.error("Google Tag Manager Script failed: " + e)
            });
    }

    copyCode() {
        this.startCopy = true;
        // reset to default state
        setTimeout(() => {
            this.startCopy = false;
        }, 2000);
    }
}
