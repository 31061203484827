<img loading="lazy" alt="" height="1" width="1"
	*ngIf="colour !== 'white' && colour !== 'blue'"
	src="https://cdn.ciphersports.io/images/dimers-site/sports-icons-2023/light-bg/{{sport.ballCode || sport.code}}.svg"
>
<img loading="lazy" alt="" height="1" width="1"
	*ngIf="colour === 'white' || colour === 'blue'"
	src="https://cdn.ciphersports.io/images/dimers-site/sports-icons-2023/dark-bg/{{sport.ballCode || sport.code}}.svg"
>
<!-- <img loading="lazy" alt="" height="1" width="1"
	*ngIf="colour === 'blue'"
	src="https://cdn.ciphersports.io/images/dimers-site/sports-icon-no-box/{{sport.ballCode || sport.code}}-alt2.svg"
> -->