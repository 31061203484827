<div class="game-info">
	<span *ngIf="!matchService.startsWithinHour(match) && (matchService.isUpcoming(match) || (match | isPreMatchOnly))">
        {{ matchService.formattedDate(match.MatchData.UserDate) }}
    </span>
	<div class="status-box-ctr" *ngIf="matchService.startsWithinHour(match) || (!matchService.isUpcoming(match) && !(match | isPreMatchOnly))">
		<div class="status-box mini upcoming" *ngIf="matchService.startsWithinHour(match)">
			<div class="box-main">STARTS</div>
			<div class="box-second">{{ matchService.matchStartCountdown(match, false) }}</div>
		</div>
		<div class="status-box mini live" *ngIf="(match | isLive)">
			<div class="box-main">LIVE</div>
			<div class="box-second">{{ matchService.liveStatusString(match) }}</div>
		</div>
		<div class="status-box mini paused" *ngIf="(match | isPaused)">
			<div class="box-main">
				SUSPENDED
			</div>
			<div class="box-second"></div>
		</div>
		<div class="status-box mini finished" *ngIf="(match | isOver)">
			<div class="box-main">FINAL</div>
			<div class="box-second"></div>
		</div>
	</div>
</div>
<div class="teams-and-info">
	<div class="teams-col"
        [class.compressed]="((match.MatchData.Sport | lowercase) === 'mlb' || (match.MatchData.Sport | lowercase) === 'nfl' || (match.MatchData.Sport | lowercase) === 'cfb') && (match | isLive)">
		<div class="team-row away-team">
			<!-- <div class="team-logo">
				<app-team-icon draggable="false" [sportCode]="match.MatchData.Sport" [team]="match.MatchData.AwayTeam"></app-team-icon>
			</div> -->
			<div class="team-name" [class.greyed]="(match | isOver) && match.LiveData.homeScore > match.LiveData.awayScore">
				<span>{{ match.MatchData.AwayTeam.Abv }}</span>
				<span class="poss-icon" role="note" aria-label="In Possession" *ngIf="(match | isLive) && ((match.MatchData.Sport | lowercase) === 'nfl' || (match.MatchData.Sport | lowercase) === 'cfb') && match.LiveData.teamWithBallSI === match.MatchData.AwayTeam.Abv"></span>
				<!-- <div class="pick-icon" *ngIf="match.PreData.PythagAway > match.PreData.PythagHome"></div> -->
			</div>
			<div class="team-score" *ngIf="!matchService.isUpcoming(match) && !(match | isPreMatchOnly)"
				[class.just-changed]="match.awayScoreJustChanged"
                [class.greyed]="(match | isOver) && match.LiveData.homeScore > match.LiveData.awayScore">
				{{ match.LiveData.awayScore }}
			</div>
			<div class="team-prob" *ngIf="((match | isLive) || (match | isPaused))
				&& match.LiveData.awayWinProb !== undefined">
				({{ generalService.hedgedProbString(match.LiveData.awayWinProb, 0) }})
			</div>
			<div class="team-prob pre-match" *ngIf="matchService.isUpcoming(match)">
				{{ (match.PreData.PythagAway * 100) | number: '1.0-0' }}%
			</div>
			<div class="bookie-icon-ctr" *ngIf="(matchService.isUpcoming(match) || (match | isPreMatchOnly)) && !match.bettingInfoUnavailable">
				<img loading="lazy"
					*ngIf="(match.MatchData.Sport | lowercase) !== 'mlb' && (match.MatchData.Sport | lowercase) !== 'nhl'
						&& !generalService.isSoccer(match.MatchData.Sport)"
					class="bookie-icon"
					[src]="match.aggregatedBettingInfo?.lineAwayProvider | bookIcon"
					[alt]="betService.stylisedBookName(match.aggregatedBettingInfo?.lineAwayProvider)"
				>
				<img loading="lazy"
					*ngIf="(match.MatchData.Sport | lowercase) === 'mlb' || (match.MatchData.Sport | lowercase) === 'nhl'
						|| generalService.isSoccer(match.MatchData.Sport)"
					class="bookie-icon"
					[src]="match.aggregatedBettingInfo?.h2hAwayProvider | bookIcon"
					[alt]="betService.stylisedBookName(match.aggregatedBettingInfo?.h2hAwayProvider)"
				>
			</div>
			<div class="team-line" *ngIf="(matchService.isUpcoming(match) || (match | isPreMatchOnly)) && !match.bettingInfoUnavailable">
				<div class="line-box"
					*ngIf="(match.MatchData.Sport | lowercase) !== 'mlb' && (match.MatchData.Sport | lowercase) !== 'nhl'
						&& !generalService.isSoccer(match.MatchData.Sport)"
					[style.backgroundColor]="match.aggregatedBettingInfo.lineAwayProvider | bookBackgroundColour"
					[style.color]="match.aggregatedBettingInfo.lineAwayProvider | bookForegroundColour"
				>
                    {{ match.aggregatedBettingInfo.HomeLine < 0 ? '+' : '' }}{{ (match.aggregatedBettingInfo.HomeLine * -1) | number: '1.1-1' }}
                </div>
				<div class="line-box"
					*ngIf="(match.MatchData.Sport | lowercase) === 'mlb' || (match.MatchData.Sport | lowercase) === 'nhl'
						|| generalService.isSoccer(match.MatchData.Sport)"
					[style.backgroundColor]="match.aggregatedBettingInfo.h2hAwayProvider | bookBackgroundColour"
					[style.color]="match.aggregatedBettingInfo.h2hAwayProvider | bookForegroundColour"
				>
                    {{ betService.formatOdds(match.aggregatedBettingInfo.AwayOdds) | async }}
                </div>
			</div>
			<div class="winner-indi" *ngIf="(match | isOver)">
				<fa-icon class="winner-arrow" [icon]="faCaretLeft" *ngIf="match.LiveData.awayScore > match.LiveData.homeScore"></fa-icon>
			</div>
		</div>
		<div class="team-row home-team">
			<!-- <div class="team-logo">
				<app-team-icon draggable="false" [sportCode]="match.MatchData.Sport" [team]="match.MatchData.HomeTeam"></app-team-icon>
			</div> -->
			<div class="team-name" [class.greyed]="(match | isOver) && match.LiveData.awayScore > match.LiveData.homeScore">
				<span>{{ match.MatchData.HomeTeam.Abv }}</span>
				<span class="poss-icon" role="note" aria-label="In Possession" *ngIf="(match | isLive) && ((match.MatchData.Sport | lowercase) === 'nfl' || (match.MatchData.Sport | lowercase) === 'cfb') && match.LiveData.teamWithBallSI === match.MatchData.HomeTeam.Abv"></span>
				<!-- <div class="pick-icon" *ngIf="match.PreData.PythagHome > match.PreData.PythagAway"></div> -->
			</div>
			<div class="team-score" *ngIf="!matchService.isUpcoming(match) && !(match | isPreMatchOnly)"
				[class.just-changed]="match.homeScoreJustChanged"
                [class.greyed]="(match | isOver) && match.LiveData.awayScore > match.LiveData.homeScore">
				<span>{{ match.LiveData.homeScore }}</span>
			</div>
			<div class="team-prob" *ngIf="((match | isLive) || (match | isPaused))
				&& match.LiveData.awayWinProb !== undefined">
				({{ generalService.hedgedProbString(match.LiveData.homeWinProb, 0) }})
			</div>
			<div class="team-prob pre-match" *ngIf="matchService.isUpcoming(match)">
                {{ (match.PreData.PythagHome * 100) | number: '1.0-0' }}%
			</div>
			<div class="bookie-icon-ctr" *ngIf="(matchService.isUpcoming(match) || (match | isPreMatchOnly)) && !match.bettingInfoUnavailable">
				<img loading="lazy"
					*ngIf="(match.MatchData.Sport | lowercase) !== 'mlb' && (match.MatchData.Sport | lowercase) !== 'nhl'
						&& !generalService.isSoccer(match.MatchData.Sport)"
					class="bookie-icon"
					[src]="match.aggregatedBettingInfo?.lineHomeProvider | bookIcon"
					[alt]="betService.stylisedBookName(match.aggregatedBettingInfo?.lineHomeProvider)"
				>
				<img loading="lazy"
					*ngIf="(match.MatchData.Sport | lowercase) === 'mlb' || (match.MatchData.Sport | lowercase) === 'nhl'
						|| generalService.isSoccer(match.MatchData.Sport)"
					class="bookie-icon"
					[src]="match.aggregatedBettingInfo?.h2hHomeProvider | bookIcon"
					[alt]="betService.stylisedBookName(match.aggregatedBettingInfo?.h2hHomeProvider)"
				>
			</div>
			<div class="team-line" *ngIf="(matchService.isUpcoming(match) || (match | isPreMatchOnly)) && !match.bettingInfoUnavailable">
				<div class="line-box"
					*ngIf="(match.MatchData.Sport | lowercase) !== 'mlb' && (match.MatchData.Sport | lowercase) !== 'nhl'
						&& !generalService.isSoccer(match.MatchData.Sport)"
					[style.backgroundColor]="match.aggregatedBettingInfo.lineHomeProvider | bookBackgroundColour"
					[style.color]="match.aggregatedBettingInfo.lineHomeProvider | bookForegroundColour"
				>
                    {{ match.aggregatedBettingInfo.HomeLine > 0 ? '+' : '' }}{{ match.aggregatedBettingInfo.HomeLine | number: '1.1-1' }}
                </div>
				<div class="line-box"
					*ngIf="(match.MatchData.Sport | lowercase) === 'mlb' || (match.MatchData.Sport | lowercase) === 'nhl'
						|| generalService.isSoccer(match.MatchData.Sport)"
					[style.backgroundColor]="match.aggregatedBettingInfo.h2hHomeProvider | bookBackgroundColour"
					[style.color]="match.aggregatedBettingInfo.h2hHomeProvider | bookForegroundColour"
				>
                    {{ betService.formatOdds(match.aggregatedBettingInfo.HomeOdds) | async }}
                </div>
			</div>
			<div class="winner-indi" *ngIf="(match | isOver)">
				<fa-icon class="winner-arrow" [icon]="faCaretLeft" *ngIf="match.LiveData.homeScore > match.LiveData.awayScore"></fa-icon>
			</div>
		</div>
	</div>
	<!-- TODO add this as appropriate (e.g. MLB live, NFL live) -->
	<div class="info-col" *ngIf="(match.MatchData.Sport | lowercase) === 'mlb' && (match | isLive)">
		<svg class="base-diagram" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			width="70px" height="50px" viewBox="0 0 70 50" enable-background="new 0 0 70 50" xml:space="preserve">
			<rect x="9" y="23" class="base" [class.occupied]="match.LiveData.runnerOnThird" transform="matrix(0.7071 0.7071 -0.7071 0.7071 28.9005 -3.7685)" stroke-miterlimit="10" width="18" height="18"/>
			<rect x="25" y="7" class="base" [class.occupied]="match.LiveData.runnerOnSecond" transform="matrix(0.7071 0.7071 -0.7071 0.7071 22.2731 -19.7693)" stroke-miterlimit="10" width="18" height="18"/>
			<rect x="41" y="23" class="base" [class.occupied]="match.LiveData.runnerOnFirst" transform="matrix(0.7071 0.7071 -0.7071 0.7071 38.2738 -26.3967)" stroke-miterlimit="10" width="18" height="18"/>
		</svg>
		<div class="outs-string">
			{{ match.LiveData.outs }} Out
		</div>
	</div>
	<div class="info-col wider"
		*ngIf="((match.MatchData.Sport | lowercase) === 'nfl' || (match.MatchData.Sport | lowercase) === 'cfb') && (match | isLive)"
		[class.red-zone]="matchService.teamInRedZone(match.LiveData)">
		<span class="amfb-info">{{ matchService.downAndDistanceSummary(match.LiveData) }}</span>
		<span class="amfb-info">{{ match.LiveData.actionSideSI }} {{ match.LiveData.actionYardLine }}</span>
	</div>
</div>