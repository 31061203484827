<div class="full-column">
    <div *ngIf="authHelper.userRetrievalDone && !authHelper.user || originalTarget === '/null'; else emailVerify;">
        Sorry, something went wrong loading this page.
    </div>
    <ng-template #emailVerify>
        <div *ngIf="!this.userLoading && !this.userError" class="max-w-lg mx-auto py-6 md:py-12">
            <div class="dimers-basic-logo flex justify-center">
                <img src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" alt="logo" width="100" height="100">
            </div>
                <div class="relative">
                    <h1 class="text-2xl md:text-5xl font-bold text-center">Verify your email</h1>
            
                    <p class="text-center text-base text-gray-900 leading-5 font-medium">We’ve sent you an email with a verification
                        link to
                        <span class="text-highlight-100 font-bold">{{ email }}</span>.
                    </p>
            
                    <p class="text-gray-600 text-sm leading-5 text-center italic font-medium">
                        Once verified, please come back to this window and click on <span class="text-highlight-100 font-semibold inline-block">Go to Checkout</span> button.
                    </p>
                    <div class="relative mt-4">
                        <ul role="list" class="list-none m-0">
                            <li>
                                <div class="relative min-h-[60px] pb-6 sm:pb-0">
                                    <span class="absolute left-6 top-5 -ml-px h-full w-0.5 bg-black" aria-hidden="true">
                                    </span>
                                    <div class="relative flex items-center sm:items-start space-x-4">
                                        <div class="relative px-2">
                                            <div
                                                class="flex h-8 w-8 items-center justify-center rounded-full bg-navy-50 ring-4 ring-black">
                                                <img class="state-icon"
                                                    src="https://imgix.cosmicjs.com/e87d0d90-df63-11ee-a698-6f33e3e4a628-Dimers-Round-Logo-1.svg"
                                                    alt="icon" width="28" height="28">
                                            </div>
                                        </div>
                                        <div class="min-w-0 flex-1">
                                            <div class="mt-1 flex flex-col items-start md:flex-row gap-x-2">
                                                <div
                                                    class="inline-flex items-center gap-x-1.5 rounded-full px-3 py-1 text-sm font-medium text-neutral-600 ring-1 ring-inset ring-gray-300">
                                                    <svg class="fill-black h-1.5 w-1.5 min-w-[6px]" viewBox="0 0 6 6"
                                                        aria-hidden="true">
                                                        <circle cx="3" cy="3" r="3"></circle>
                                                    </svg>Sign up to Dimers
                                                    <div class="ml-2 text-xs font-medium text-green-700 rounded-2xl bg-green-300 px-2 py-[2px]" >Done</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="relative min-h-[60px] pb-6 sm:pb-0">
                                    <span class="absolute left-6 top-5 -ml-px h-full w-0.5 bg-black" aria-hidden="true">
                                    </span>
                                    <div class="relative flex items-center sm:items-start space-x-4">
                                        <div class="relative px-2">
                                            <div
                                                class="flex h-8 w-8 items-center justify-center rounded-full bg-navy-50 ring-4 ring-black">
                                                <img class="state-icon"
                                                    src="https://imgix.cosmicjs.com/da8ce510-1803-11ef-9b79-cf0caf532ff4-fimail.svg"
                                                    alt="icon" width="18" height="18">
                                            </div>
                                        </div>
                                        <div class="min-w-0 flex-1 relative">
                                            <div class="mt-1 flex flex-col items-start md:flex-row gap-x-2">
                                                <div
                                                    class="inline-flex items-center gap-x-1.5 rounded-full px-3 py-1 text-sm font-medium text-neutral-600 ring-1 ring-inset ring-gray-300">
                                                    <svg class="fill-black h-1.5 w-1.5 min-w-[6px]" viewBox="0 0 6 6"
                                                        aria-hidden="true">
                                                        <circle cx="3" cy="3" r="3"></circle>
                                                    </svg>Verify your email. Check Spam
                                                    <img src="https://imgix.cosmicjs.com/4d9800a0-18b1-11ef-b483-0d316c73c385-ufocus.svg" alt="icon" width="18" height="18">
                                                </div>
                                            </div>
                                            <div class="check-email-btn-group hidden absolute sm:grid grid-cols-2 sm:grid-cols-1 sm:top-0 sm:right-3 mt-2 sm:mt-0 gap-2 sm:gap-0 ">
                                                <button onclick="window.open('https://mail.google.com/mail/u/0/#inbox', '_blank')"
                                                    class="bg-AIDimers-grey px-2 h-8 rounded text-sm font-medium text-gray-900 flex justify-center items-center gap-1 max-w-[160px] whitespace-nowrap">
                                                    <img src="https://imgix.cosmicjs.com/4211aea0-189a-11ef-bf16-018e287714eb-skill-iconsgmail-light.svg"
                                                        alt="gmail-icon" width="18" height="18">
                                                    Open Gmail
                                                </button>
                                                <button onclick="window.open('https://outlook.live.com/mail/inbox', '_blank')"
                                                    class="bg-AIDimers-grey px-2 h-8 rounded text-sm font-medium text-gray-900 sm:mt-1 flex justify-center items-center gap-1 max-w-[160px]  whitespace-nowrap">
                                                    <img src="https://imgix.cosmicjs.com/4211fcc0-189a-11ef-b483-0d316c73c385-vscode-iconsfile-type-outlook.svg"
                                                        alt="outlook-icon" width="18" height="18">
                                                    Open Outlook
                                                </button>
                                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="relative min-h-[60px] pb-6 sm:pb-0">
                                    <div class="relative flex items-center sm:items-start space-x-3">
                                        <div class="relative px-2">
                                            <div
                                                class="flex h-8 w-8 items-center justify-center rounded-full bg-navy-50 ring-4 ring-highlight-100">
                                                <img class="state-icon" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                                                alt="icon" width="28" height="28">
                                            </div>
                                        </div>
                                        <div class="min-w-0 flex-1">
                                            <div class="flex flex-col items-start md:flex-row gap-x-3">
                                                <div
                                                    class="inline-flex items-center gap-x-1.5 px-3">
                                                    <a [href]="originalTarget" id="checkout" class="proceed-button max-sm:col-end-3 max-sm:col-span-1 bg-highlight-100 text-white px-2 h-8 rounded text-sm font-medium flex justify-center items-center max-w-[160px]  whitespace-nowrap"> 
                                                        Go To Checkout
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="text-base font-semibold text-center leading-5 mt-4">
                            🎉 Access Every Bet, Every Game, Every Day
                        </div>
                        <div class="text-sm font-medium text-center leading-5 mt-4">
                            <ng-container *ngIf="phase === 'pre-send' || countdown === 0 && countdownStarted"><a class="underline" (click)="sendVerifyEmail()">Request a new link here</a></ng-container>
                            <ng-container *ngIf="this.countdown > 0"><span class="text-gray-300">Email sent ({{ this.countdown}}...)</span> </ng-container>
                            <div class="error text-red-500 font-semibold" *ngIf="sendError">Sorry, something went wrong resending the verify email. Please try again.</div>
                        </div>
                        <div class="text-sm font-medium text-center leading-5 mt-4">
                           You can <a href="/contact-us" target="_blank">contact us here</a> if you have any issues.
                        </div>
                    </div>
                </div>
        </div>
        <div *ngIf="userLoading" class="flex justify-center items-center min-h-[630px] max-w-lg mx-auto py-6 md:py-16">
            <div role="status">
                <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
        </div></ng-template>
</div>
