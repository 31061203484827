import { Component, OnInit, PLATFORM_ID, Inject, HostListener } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { faTimes, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { DOCUMENT, isPlatformBrowser, NgIf, NgFor } from '@angular/common';
import { RecaptchaErrorParameters, RecaptchaModule } from "ng-recaptcha";
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BlogService } from 'src/app/blog.service';
import { environment } from "src/environments/environment";
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-feedback-form',
    templateUrl: './feedback-form.component.html',
    styleUrls: ['./feedback-form.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        FontAwesomeModule,
        FormsModule,
        NgFor,
        ReactiveFormsModule,
        RecaptchaModule,
        RouterLink,
    ],
})
export class FeedbackFormComponent implements OnInit {

    userType:string = 'Not logged in';
	faMessageLines = faMessageLines;
	faTimes = faTimes;
	faXmark = faXmark;
	showFeedback: boolean = true;
    showFeedbackOnscroll: boolean = false;
	formVisible: boolean = false;
	submitted: boolean = false;
	sendSuccessful: boolean = false;
	sendError: boolean = false;
	submitLoading: boolean = false;
	browserMode: boolean = false;
	public captchaResponse = "";
	showFeedbackFormContent:boolean = false;

    private scrollThreshold: number;
    private currentScroll: number = 0;
    
	siteKey = environment.captchaKey;
	feedbackFormData = this.fb.group({
		email: ['',  [Validators.email, Validators.required]],
		comment: ['', Validators.required]
	});

	topicSubmitted:boolean = false;
	ratescoreErrorMessage:boolean = false;

    selectedOption:string;
    questions: string[] = ['Ask a question', 'Leave a comment', 'Report a bug', 'Suggest an improvement', 'Other'];
    commentTitles: string;
	constructor(
		@Inject(PLATFORM_ID) platformId: string,
		private fb: FormBuilder,
		@Inject(DOCUMENT) public document: Document,
        public authHelper: AuthHelperService,
        public blogService: BlogService,
        private router: Router,
	) {
		this.browserMode = isPlatformBrowser(platformId);
	}

	ngOnInit(): void {
        if(this.browserMode) {
            this.getUserData();
            // two full screen heights
    		this.scrollThreshold =  window.innerHeight * 2; 
        }
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe((event: NavigationEnd) => {
            const hiddenRoutes = ['/email-verify-required'];
            this.showFeedback = !hiddenRoutes.includes(event.urlAfterRedirects);
          });
	}

    @HostListener('window:scroll', [])
    onWindowScroll() {
      const scrollPosition = window.scrollY || document.documentElement.scrollTop;
      this.currentScroll = scrollPosition;
  
      if (this.currentScroll >= this.scrollThreshold) {
        this.showFeedbackOnscroll = true;
      }
    }
    
    // store user email and user type information into form
    getUserData() {
        this.authHelper.detailedUser$.subscribe(res=>{
            if(res)
            {
                this.userType = res.app_metadata?.dimers_pro? "Dimers Pro" : "Dimers Basic";
                this.feedbackFormData.patchValue({
                    email: res.email
                });
            }
        })
    }

	public resolved(captchaResponse: string): void {
		this.captchaResponse = captchaResponse;
		if(this.captchaResponse) {
			this.onSubmit();
		}
	  }
	
	public onError(errorDetails: RecaptchaErrorParameters): void {
		this.captchaResponse += `ERROR;\n`;
		// console.log(`reCAPTCHA error encountered; details:`, errorDetails);
	}

	submitCheck(captchaRef: any): void {
		this.submitLoading = true;

        if (this.captchaResponse) {
            captchaRef.reset();
        }
        captchaRef.execute();
	}

	showFeedbackFormPopup(): void {
		this.formVisible = true;
	}

	closefeedbackForm(): void {
		this.formVisible = false;
		this.sendError = false;
		this.submitted = false;
		this.sendSuccessful = false;
        this.topicSubmitted =false;
	}

    nextStep() {
        if(this.selectedOption === 'Ask a question')  this.commentTitles = 'What would you like to know?*';
        if(this.selectedOption === 'Leave a comment') this.commentTitles = "Let us know what's on your mind*";
        if(this.selectedOption === 'Other')  this.commentTitles = 'Comment*';
        if(this.selectedOption === 'Report a bug')  this.commentTitles = 'Describe the bug or issue*';
        if(this.selectedOption === 'Suggest an improvement') this.commentTitles = "Let us know what you'd like to improve*";
        this.topicSubmitted = true;
    }

	onSubmit() {
		if (!this.submitted && this.feedbackFormData.valid) {
			this.submitted = true;
			this.sendSuccessful = false;
			this.sendError = false;
    
            this.blogService
                .createTicketInZoho(
                    this.selectedOption,
                    this.feedbackFormData.value.email,
                    this.feedbackFormData.value.comment,
                    this.userType,
                    undefined,
                    undefined,
                    "feedback-form"
                    )
                .subscribe(res => {
                    this.feedbackFormData.patchValue({
                        comment: ''
                    });
                    this.sendSuccessful = true;
                    this.submitLoading = false;
                    this.showFeedbackFormContent = false;
                    this.submitted = false;
                    this.topicSubmitted = false;
                }, () => {
                    this.submitted = false;
                    this.sendError = true;
                    this.sendSuccessful = false;
                    this.submitLoading = false;
                });
		}
	}
}