import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, of, map } from 'rxjs';
import { AuthHelperService } from './auth-helper.service';
import { HttpClient } from '@angular/common/http';
import { GoogleTagManagerService } from './angular-google-tag-manager.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie';
import { BlogService } from './blog.service';

@Injectable({
  providedIn: 'root'
})
export class ProBuyLinkGuard implements CanActivate {

    clientID:String;
    freeTrail:number;
  constructor(
    private authHelper: AuthHelperService,
    private router: Router,
    private http: HttpClient,
    private gtmService: GoogleTagManagerService,
    private cookieService: CookieService,
    private blogService: BlogService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (typeof window !== "undefined") {
          const paymentRate = route.paramMap.get("rate") || "monthly";
          const campaign = route.queryParams.utm_source;
          const offer = route.queryParams.offer;
          console.log("probuylinkguard canActivate");
          return this.authHelper.detailedUser$.pipe(    
              map((user) => {
                  if (user.app_metadata?.dimers_pro) {
                      this.router.navigateByUrl(`my-account`);
                      this.blogService.setProModalData(false);
                  } else if (!user.email_verified) {
                      if(campaign){
                        this.router.navigateByUrl(`email-verify-required?dest=pro-purchase-campaign?utm_source=${campaign}`);
                      } else if(offer){
                          this.router.navigateByUrl(`email-verify-required?dest=pro-purchase-special-offer?offer=${offer}`);
                      } else {
                        this.router.navigateByUrl(`email-verify-required?dest=pro-purchase/${paymentRate}`);
                      }
                      this.blogService.setProModalData(false);
                  } else {
                      if(campaign === "campaign" || offer === "free_trial" || offer === "facebook_trial" ) {
                        this.freeTrail = 7;
                      }
                      const pattern = "GA1.1.";
                      // Use proxy to Stripe API to get link based on paymentRate
                      (window as any).ga?.getAll().forEach((tracker) => {
                          this.clientID = tracker.get('clientId');
                      });

                      this.clientID = this.clientID || this.cookieService.get('_ga')?.substring(pattern.length);

                      this.http.post<any>(`${environment.dimersProApi}/checkout`,
                          {
                              "price_id": environment.dimersProPriceMap?.[paymentRate] || "no_price",
                              "customer_id": user.app_metadata.stripe_customer_id,
                              "success_url": window.location.origin + "/payment-success/" + paymentRate,
                              "cancel_url": window.location.origin + "/payment-cancel",
                              "ga_client": this.clientID || "unknown",
                              "referral": (window as any).promotekit_referral || undefined,
                              "trial_days": this.freeTrail
                          },
                          {
                              headers: {
                                  "Content-Type": "application/json"
                              }
                          }
                      ).subscribe({
                          next: (response) => {
                              const gtmTag = {
                                  event: "pro_checkout",
                                  event_category: paymentRate || "monthly",
                              };
                              this.gtmService.pushTag(gtmTag)
                                  .then(() => {
                                        window.location.href = response.url;    
                                        this.blogService.setProModalData(false);
                                  })
                                  .catch(e => {
                                        console.error("Google Tag Manager Script failed: " + e)
                                        window.location.href = response.url;
                                        this.blogService.setProModalData(false);
                                  });
                              
                          },
                          error: (err) => {
                              console.log(err);
                              return false;
                          }
                      })
                  }
                  return false;
              }),
              catchError(() => {
                  // TODO what if the user retrieval fails?
                  return of(false);
              })
          )
      }
      
  }
  
}
