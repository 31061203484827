import { Component, Input, OnInit } from '@angular/core';
import { faChevronDown, faChevronRight, faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import { map, Observable, shareReplay } from 'rxjs';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { NavigationItem } from 'src/app/navigation-item.model';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-desktop-navigation-item',
    templateUrl: './desktop-navigation-item.component.html',
    styleUrls: ['./desktop-navigation-item.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, FontAwesomeModule, NgFor, AsyncPipe]
})
export class DesktopNavigationItemComponent implements OnInit {
  @Input() navItem: NavigationItem;
  @Input() condensedSecondLevel: boolean;
  @Input() dropdownCentred: boolean;
  @Input() firstItem: boolean;

  nested = false;
  dropdownOpen = false;
  activeTopLevel = 0;

  setActiveTopLevel(index: number): void {
    this.activeTopLevel = index;
  }

  

  proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_pro
		}),
		shareReplay(1)
	);

  
	faChevronDown = faChevronDown;
	faChevronRight = faChevronRight;
  faEllipsisH = faEllipsisH;

  constructor(
    private authHelper: AuthHelperService,
  ) {}

  ngOnInit(): void {
    this.nested = this.navItem?.subLinks?.some(l => l.subLinks?.length > 0)
  }

  toggleDropdown(): void {
		this.dropdownOpen = !this.dropdownOpen;
	}

	showDropdown(): void {
		this.dropdownOpen = true;
	}
	
	hideDropdown(): void {
		this.dropdownOpen = false;
	}
}
